import { Box, Stack } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Button, useColorMode, Icon, Text, HStack, Center } from '@chakra-ui/react';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import SwapForm from './SwapForm';
import { i_h3 } from '../../../style';
import Tabs from '../components/Tabs';
import AdvanceSwap from './AdvanceSwap';
import SwapSetting from './components/SwapSetting';
import useIsMobile from '../../../hooks/useIsMobile';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy } from '../../../iZUMi-UI-toolkit/src/style';
import useLayoutType from '../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import JumpButton from '../../../components/JumpButton/JumpButton';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';

const Swap: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode } = useSelector((state: RootState) => state);
    const layoutType = useLayoutType();

    const isSimpleMode = tradeMode.isSimpleMode;
    const [showWarning, setShowWarning] = useState(true);

    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [dispatch]
    );

    const location = useLocation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;

    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }
        document.title = t('Exchange') + ' | iZiSwap';
    }, [location.state, setIsSimpleMode, t]);

    const {
        tradeSwap: { swapForm },
    } = useSelector((state: RootState) => state);

    const tabValue = 'Swap';
    const handleChangeTab = (value: string) => {
        if (value === 'Limit Order') {
            history.push('/limit');
        }
    };
    const isMobile = useIsMobile();
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <SwapForm type="mobile" />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                isSimpleMode ? (
                    <Box h="0px"></Box>
                ) : (
                    <PageHeader
                        w={{
                            base: '700px',
                            lg: '1113px',
                            xxl: '1113px',
                            xlp1: '1150px',
                        }}
                        headerTitle={
                            <HStack spacing="20px">
                                <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                    {t('Exchange')}
                                </Heading>
                                <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                                <Text className={i_h3} fontSize={oneLineMode ? '23px' : '19px'} color={colorTheme('#529B96', '#21B0A6')}>
                                    {t('Swap')}
                                </Text>
                                <CustomButton
                                    w="60px"
                                    h="25px"
                                    className={i_text_copy}
                                    fontWeight="600"
                                    bg="linear-gradient(92.16deg, #1BA49B -17.44%, #28E0E0 105.78%);"
                                    color="#FFFFFF"
                                    borderRadius="3px"
                                    onClick={() => {
                                        if (layoutType === LayoutStateEnum.sidebar) {
                                            dispatch.pageLayout.setIsInAnimation(true);
                                            setTimeout(() => {
                                                dispatch.pageLayout.setIsInAnimation(false);
                                            }, 500);
                                        }
                                        history.push('/pro/');
                                    }}
                                    text="Pro"
                                    leftIcon={'/assets/swap/extraLink.svg'}
                                    ml={{ base: '8px !important', lg: '14px !important' }}
                                ></CustomButton>
                            </HStack>
                        }
                        subtitle={t('Swap tokens in an instant.')}
                        element={
                            <HStack spacing="10px">
                                <SwapSetting
                                    w="34px"
                                    h="34px"
                                    exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                    slippage={swapForm.slippagePercent}
                                    maxDelay={swapForm.maxDelay}
                                    quoterType={swapForm.quoterType}
                                    handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                    handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                    handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                    handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                                />
                                <Button
                                    w="34px"
                                    h="34px"
                                    mr="10px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('#EBEAEC', '#21B0A6')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    fontSize="14px"
                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                    p="7px"
                                >
                                    <Icon as={MdAddChart} boxSize="20px" />
                                </Button>
                                <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/liquidity" mr="10px" />
                                <NetworkSelect />
                            </HStack>
                        }
                    />
                )
            }
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center
                                pt={{ base: '56px', sm: '29px', xxl: '56px' }}
                                w={{ base: '100%', sm: '637px', xxl: '100%' }}
                                mt="0px !important"
                                alignItems="start"
                                alignSelf="center"
                            >
                                <Tabs
                                    variant={isSimpleMode ? 'simple' : 'none'}
                                    list={[
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: <SwapForm />,
                                        },
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: <Box>Limit Order Component </Box>,
                                        },
                                    ]}
                                    endList={[
                                        {
                                            label: t('pro'),
                                            value: 'pro',
                                            component: isXXl ? (
                                                <CustomButton
                                                    w="60px"
                                                    h="25px"
                                                    className={i_text_copy}
                                                    fontWeight="600"
                                                    bg="linear-gradient(92.16deg, #1BA49B -17.44%, #28E0E0 105.78%);"
                                                    color="#FFFFFF"
                                                    borderRadius="3px"
                                                    onClick={() => {
                                                        if (layoutType === LayoutStateEnum.sidebar) {
                                                            dispatch.pageLayout.setIsInAnimation(true);
                                                            setTimeout(() => {
                                                                dispatch.pageLayout.setIsInAnimation(false);
                                                            }, 500);
                                                        }
                                                        history.push('/pro/');
                                                    }}
                                                    text="Pro"
                                                    leftIcon={'/assets/swap/extraLink.svg'}
                                                ></CustomButton>
                                            ) : (
                                                <></>
                                            ),
                                        },

                                        {
                                            label: t('Setting'),
                                            value: 'Setting',
                                            component: (
                                                <SwapSetting
                                                    h={isSimpleMode ? { base: '30px', sm: '26px', xxl: '30px' } : '30px'}
                                                    minH={isSimpleMode ? { base: '30px', sm: '26px', xxl: '30px' } : 'unset'}
                                                    exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                                    slippage={swapForm.slippagePercent}
                                                    maxDelay={swapForm.maxDelay}
                                                    quoterType={swapForm.quoterType}
                                                    handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                                    handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                                    handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                                    handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                                                />
                                            ),
                                        },
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: (
                                                <Button
                                                    maxW="38px !important"
                                                    h={isSimpleMode ? { base: '30px', sm: '24px', xxl: '30px' } : '30px'}
                                                    borderRadius="3px"
                                                    bgColor={colorTheme('#EBEAEC', '#21B0A6')}
                                                    color={colorTheme('#52466A', '#EBEAEC')}
                                                    fontSize="14px"
                                                    minW={isSimpleMode ? { base: 'unset', sm: '25px', xxl: 'unset' } : 'unset'}
                                                    px={isSimpleMode ? { base: '12px', sm: '4px', xxl: '12px' } : 'unset'}
                                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                                >
                                                    <Icon as={MdAddChart} boxSize="20px" />
                                                </Button>
                                            ),
                                        },
                                        {
                                            label: t('Liquidity'),
                                            value: 'Liquidity',
                                            component: (
                                                <JumpButton
                                                    w={{ base: '100px', sm: '80px', xxl: '100px' }}
                                                    h={{ base: '30px', sm: '24px', xxl: '30px' }}
                                                    text={t('Liquidity')}
                                                    target="/liquidity"
                                                />
                                            ),
                                        },
                                        {
                                            label: t('Network'),
                                            value: 'Network',
                                            component: (
                                                <Stack bg={colorTheme('#fbfbfb', '#010303')} color={colorTheme('#000000', '#ffffff')}>
                                                    <NetworkSelect
                                                        buttonStyle={{ h: '30px !important' }}
                                                        rightIconStyle={{ boxSize: '20px' }}
                                                        hidden={isXXl ? false : true}
                                                    />
                                                </Stack>
                                            ),
                                        },
                                    ]}
                                    value={tabValue}
                                    handleClick={handleChangeTab}
                                />
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <Stack
                        w={{
                            base: '700px',
                            lg: '1113px',
                            xxl: '1113px',
                            xlp1: '1150px',
                        }}
                    >
                        <ChainNotValidBlockWrapper
                            content={
                                <Stack>
                                    <WarningInfo isShow={showWarning} onclose={setShowWarning}></WarningInfo>
                                    <AdvanceSwap tabValue="Swap"></AdvanceSwap>{' '}
                                </Stack>
                            }
                            app="trade"
                        />
                    </Stack>
                )
            }
        />
    );
};

export default Swap;
