import React, { useEffect } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import useIsMobile from '../../hooks/useIsMobile';
import ValidBlockWrapper from '../components/ChainNotValidBlock';
import { useTranslation } from 'react-i18next';
import IPointsBlock from './IPointsBlock';

const IPoints: React.FC = () => {
    useEffect(() => {
        document.title = 'iPoints | iZiSwap';
    }, []);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="30px">
                            <Box h="14px" />
                            <IPointsBlock></IPointsBlock>
                            <Box h="20px" />
                        </Stack>
                    }
                    app="iPoints"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={<></>}
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="30px">
                            <Box h="20px" />
                            <IPointsBlock></IPointsBlock>
                            <Box h="20px" />
                        </Stack>
                    }
                    app="iPoints"
                />
            }
            internalWidth={{ base: '900px', xxl: '1000px' }}
        />
    );
};

export default IPoints;
