import React, { useState } from 'react';
import { i_text_d, i_h4 } from '../../../style';
import { BoxProps, useColorMode, HStack, Box, Image, VStack, Text, Stack, useBreakpointValue } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { formatNumber } from '../../../utils/tokenMath';
import { useTranslation } from 'react-i18next';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import Card from '../../../components/Card/Card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

type CardProps = {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    price: number | string | undefined;
    handleToggle: () => void;
    hideToggle?: boolean;
    onlyTogglePrice?: boolean;
} & BoxProps;

const CurrentPriceCard: React.FC<CardProps> = (props) => {
    const { children, tokenA, tokenB, price, handleToggle, hideToggle, onlyTogglePrice, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [togglePrice, setTogglePrice] = useState(false);
    const { chainId } = useWeb3WithDefault();
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });
    const { tradeMode } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;
    return (
        <Card
            w="100%"
            maxH={{ base: '250px', sm: 'unset', xxl: '250px' }}
            p={isSimpleMode ? { base: '10px', sm: '5px 20px 5px 66px', xxl: '10px 30px 10px 66px' } : '10px 30px 10px 66px'}
            variant="deep"
            pl="66px"
            pr="30px"
            {...rest}
        >
            <HStack w="100%" justifyContent="space-between" alignItems="center">
                <HStack alignItems="center" spacing="30px">
                    <Box
                        h={isSimpleMode ? { base: '60px', sm: '40px', xxl: '60px' } : '60px'}
                        w={isSimpleMode ? { base: '60px', sm: '40px', xxl: '60px' } : '60px'}
                        position="relative"
                    >
                        <Image
                            h={isSimpleMode ? { base: '44px', sm: '20px', xxl: '44px' } : '44px'}
                            w={isSimpleMode ? { base: '44px', sm: '20px', xxl: '44px' } : '44px'}
                            borderRadius="5px"
                            position="absolute"
                            top={isSimpleMode ? { base: '0', sm: '5px', xxl: '0' } : '0'}
                            left={isSimpleMode ? { base: '0', sm: '5px', xxl: '0' } : '0'}
                            src={tokenA.icon}
                        />
                        <Image
                            h={isSimpleMode ? { base: '44px', sm: '20px', xxl: '44px' } : '44px'}
                            w={isSimpleMode ? { base: '44px', sm: '20px', xxl: '44px' } : '44px'}
                            borderRadius="5px"
                            position="absolute"
                            right={isSimpleMode ? { base: '0', sm: '5px', xxl: '0' } : '0'}
                            bottom={isSimpleMode ? { base: '0', sm: '5px', xxl: '0' } : '0'}
                            src={tokenB.icon}
                        />
                    </Box>

                    {(isXXl || !isSimpleMode) && (
                        <VStack spacing="-6px" alignItems="start">
                            <Text className={i_text_d} color={colorTheme('primary.600', 'primary.400')} fontSize="12px !important">
                                {t('Current Price')}
                            </Text>
                            <Text
                                className={i_h4}
                                fontSize={{ base: '14px !important', sm: '12px !important', xxl: '14px !important' }}
                                color={colorTheme('tertiary.900', 'tertiary.200')}
                            >
                                {tokenA.symbol}/{tokenB.symbol}
                            </Text>
                        </VStack>
                    )}
                </HStack>

                <HStack spacing="10px">
                    <HStack alignItems="center" spacing={{ base: '30px', sm: '20px', xxl: '30px' }} hidden={price === undefined}>
                        <Stack alignItems="start" spacing="0px">
                            <Stack direction={{ base: 'row', sm: 'row', xxl: 'row' }} spacing={{ base: '8px', sm: '2px', xxl: '8px' }}>
                                <Text
                                    className={i_text_d}
                                    lineHeight="15px"
                                    color={colorTheme('primary.600', 'primary.400')}
                                    fontSize="12px !important"
                                >
                                    1 {onlyTogglePrice ? (togglePrice ? tokenB.symbol : tokenA.symbol) : tokenA.symbol}
                                </Text>
                                <Text
                                    className={i_h4}
                                    lineHeight="15px"
                                    fontSize={{ base: '14px !important', sm: '12px !important', xxl: '14px !important' }}
                                >
                                    ={' '}
                                    {formatNumber(
                                        (onlyTogglePrice ? (togglePrice ? 1 / (Number(price) ?? 1) : Number(price)) : Number(price)) ?? 0,
                                        2,
                                        2,
                                        true
                                    )}{' '}
                                    {onlyTogglePrice ? (togglePrice ? tokenA.symbol : tokenB.symbol) : tokenB.symbol}
                                </Text>
                            </Stack>
                        </Stack>
                        <HStack spacing="10px">
                            {!hideToggle && (
                                <Image
                                    w="24px"
                                    h="20px"
                                    src={colorTheme('/assets/trade/limitOrderChange.svg', '/assets/trade/darklimitOrderChange.svg')}
                                    fallbackSrc="/assets/trade/limitOrderChange.svg"
                                    cursor="pointer"
                                    onClick={() => {
                                        onlyTogglePrice ? setTogglePrice(!togglePrice) : handleToggle();
                                    }}
                                ></Image>
                            )}
                        </HStack>
                    </HStack>
                    <Image
                        w="24px"
                        h="20px"
                        src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                        fallbackSrc="/assets/trade/limitOrderDashboard.svg"
                        cursor="pointer"
                        onClick={() => {
                            window.open(links.analyticLink + '/token?chainId=' + chainId + '&tokenAddress=' + tokenB.address);
                        }}
                    ></Image>
                </HStack>
            </HStack>
            {children}
        </Card>
    );
};

export default CurrentPriceCard;
