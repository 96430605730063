import React from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';

const CopyrightInfo: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Box mb={{ base: '0px', sm: '66px' }} fontSize="9px" alignSelf="center" fontFamily="Lato" fontWeight="bold">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={isMobile ? '219' : '184'}
                height="10"
                viewBox={isMobile ? '0 0 219 10' : '0 0 184 10'}
            >
                <text
                    id="_2021_izumi_Finance._All_rights_reserved."
                    data-name="@2022-2023 Mantle. All rights reserved."
                    className="copyright-text"
                    x="-0.501"
                    y="7.375"
                >
                    @2022-2023 Mantle. &nbsp; {t('All rights reserved.')}
                </text>
            </svg>
        </Box>
    );
};

export default CopyrightInfo;
