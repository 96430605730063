import React, { useEffect } from 'react';
import { Box, Stack, HStack, Heading, useBreakpointValue } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader/PageHeader';
import NetworkSelect from '../../components/Select/NetworkSelect/NetworkSelect';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch } from '../../state/store';
import Controls from './components/Controls';
import Summary from './components/Summary';
import { PoolsBlock } from './PoolsBlock';
import useIsMobile from '../../hooks/useIsMobile';
import ChainNotValidBlockWrapper from '../components/ChainNotValidBlock';
import JumpButton from '../../components/JumpButton/JumpButton';

const Pools: React.FC = () => {
    useEffect(() => {
        document.title = 'Pools | iZiSwap';
    }, []);
    const { t } = useTranslation();
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack pt="7px" w="100%">
                            <Summary />
                            <Controls dispatch={dispatch.pools} />
                            <PoolsBlock />
                        </Stack>
                    }
                    app="pools"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                {t('Pools')}
                            </Heading>
                        </HStack>
                    }
                    subtitle={t('Discover the hottest assets')}
                    element={
                        <HStack spacing="10px">
                            <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/liquidity" mr="10px" />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" pb="30px">
                            <Summary />
                            <Controls dispatch={dispatch.pools} />
                            <PoolsBlock />
                            <Box h="20px" />
                        </Stack>
                    }
                    app="pools"
                />
            }
            internalWidth={{ base: '700px', sm: '1150px' }}
        />
    );
};

export default Pools;
