import {
    Flex,
    Image,
    Text,
    useColorMode,
    HStack,
    Tooltip,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Divider,
    BoxProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { i_h1, i_h2, i_h3, i_h4, i_h5, i_text_cardTitle, i_text_d, i_text_piece4_content } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { links } from '../../../../config/links';
import { useTranslation } from 'react-i18next';

type OneColumnProps = {
    breakPoint: number[];
    footerList: any;
};

export const OneColumn: React.FC<OneColumnProps> = ({ breakPoint, footerList }) => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [isOpenList, setOpenList] = useState(false);

    return footerList.slice(breakPoint[0], breakPoint[1]).map((section: any, key: number) => (
        <Flex w={{ base: '180px', xl: '230px' }} direction="column" key={key} mt={{ base: '40px', md: '0px' }}>
            <Text mb="19px" color="#529B96" className={i_text_piece4_content} letterSpacing="0.1em" fontWeight="700">
                {t(section.title)}
            </Text>

            {section.items.map((item: any, index: number) =>
                item.type === 'link' ? (
                    <HStack
                        key={index}
                        mb="13px"
                        cursor="pointer"
                        onClick={
                            item.link
                                ? () => {
                                      window.open(item.link);
                                  }
                                : () => {}
                        }
                    >
                        {item.icon && (
                            <Image
                                w="14px"
                                h="14px"
                                src={process.env.PUBLIC_URL + item.icon}
                                fallbackSrc={process.env.PUBLIC_URL + item.icon}
                            />
                        )}

                        <Text color={colorTheme('#1D0928', '#F9F9F9')} className={i_text_cardTitle} fontWeight="600">
                            {t(item.text)}
                        </Text>
                    </HStack>
                ) : item.type === 'list' ? (
                    <Flex
                        key={index}
                        onMouseOver={() => {
                            setOpenList(true);
                        }}
                        onMouseLeave={() => {
                            setOpenList(false);
                        }}
                        mt="-8px"
                        pt="8px"
                        zIndex="1"
                    >
                        <Menu isOpen={isOpenList}>
                            <MenuButton>
                                <HStack key={index} mb="13px" cursor="pointer">
                                    {item.icon && (
                                        <Image
                                            w="14px"
                                            h="14px"
                                            src={process.env.PUBLIC_URL + item.icon}
                                            fallbackSrc={process.env.PUBLIC_URL + item.icon}
                                        />
                                    )}

                                    <Text color={colorTheme('#1D0928', '#F9F9F9')} className={i_text_cardTitle} fontWeight="600">
                                        {t(item.text)}
                                    </Text>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                minW="0px"
                                w={{ base: '100px', lg: '130px' }}
                                className={i_text_d}
                                bg={colorTheme('#FBFBFB', '#100C1E')}
                                position="absolute"
                                bottom="40px"
                                fontSize="13px"
                            >
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramChannel);
                                    }}
                                >
                                    Channel
                                </MenuItem>
                                <Divider></Divider>
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramCN);
                                    }}
                                >
                                    中文
                                </MenuItem>
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramEN);
                                    }}
                                >
                                    English
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                ) : (
                    <Tooltip label={item.link} key={index}>
                        <Text color={colorTheme('#1D0928', '#F9F9F9')} className={i_text_cardTitle} fontWeight="600" mb="13px">
                            {t(item.text)}
                        </Text>
                    </Tooltip>
                )
            )}
            {section.subtitle && (
                <Text color="primary.500" mt="-3px" className={i_h5}>
                    {section.subtitle}
                </Text>
            )}
        </Flex>
    ));
};

const HomePiece7: React.FC<BoxProps> = (pros) => {
    const { ...rest } = pros;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, md: true });
    const breakPoint = oneLineMode ? 1 : 2;
    const footerList = [
        {
            width: '230px',
            title: 'ABOUT',
            items: [
                {
                    text: 'Contact',
                    icon: '',
                    //link: 'mailto:contact@izumi.finance',
                    link: 'contact@izumi.finance',
                    onClick: null,
                    type: 'tooltip',
                },
                {
                    text: 'Github',
                    icon: '',
                    link: links.github,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Media Kit',
                    icon: '',
                    link: links.mediaKit,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'iZi Token',
                    icon: '',
                    link: links.iZiToken,
                    onClick: null,
                    type: 'link',
                },
            ],
            //subtitle: 'Online Store',
            subtitle: '',
        },
        {
            width: '258px',
            title: 'COMMUNITY',
            items: [
                {
                    text: 'Twitter',
                    icon: colorTheme('/assets/home/homePiece7/Twitter.svg', '/assets/icons/Twitter.svg'),
                    link: links.twitter,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Medium',
                    icon: colorTheme('/assets/home/homePiece7/Medium.svg', '/assets/icons/Medium.svg'),
                    link: links.medium,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Discord',
                    icon: colorTheme('/assets/home/homePiece7/Discord.svg', '/assets/icons/Discord.svg'),
                    link: links.discord,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Telegram',
                    icon: colorTheme('/assets/home/homePiece7/Telegram.svg', '/assets/icons/Telegram.svg'),
                    link: links.telegram,
                    onClick: null,
                    type: 'list',
                },
            ],
        },
        {
            width: '230px',
            title: 'HELP',
            items: [
                {
                    text: 'Troubleshooting',
                    icon: '',
                    link: 'mailto:contact@izumi.finance',
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Documentation',
                    icon: '',
                    link: links.docs,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Research',
                    icon: '',
                    link: links.research,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Disclaimer',
                    icon: '',
                    link: links.disclaimer,
                    onClick: null,
                    type: 'link',
                },
            ],
        },
    ];

    return (
        <Flex
            backgroundColor={colorTheme('tertiary.50', '#152A2F')}
            w="100%"
            h={{ base: '589px', md: '282px', lg: '369px' }}
            justifyContent="center"
            alignItems="center"
            pl={{ base: '30px', xl: '0px' }}
            {...rest}
        >
            <Flex
                w={{ base: '90%', md: '735px', lg: '920px' }}
                direction={{ base: 'column', md: 'row' }}
                mx="auto"
                alignItems="center"
                justifyContent="center"
            >
                <Flex w={{ base: '360px', md: '195px', lg: '208px', xl: '288px' }} h="33px" alignItems="center">
                    <Image w="33px" h="33px" src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg'} />
                    <Text className={i_h4} w="84px" ml="12px" color={colorTheme('#1D0928', '#F5F5F5')}>
                        iZiSwap
                    </Text>
                </Flex>
                <Flex direction={{ base: 'column', md: 'row' }}>
                    <>
                        <Flex>
                            <OneColumn breakPoint={[0, breakPoint]} footerList={footerList}></OneColumn>
                        </Flex>
                        <Flex>
                            <OneColumn breakPoint={[breakPoint, footerList.length]} footerList={footerList}></OneColumn>
                        </Flex>
                    </>
                </Flex>
            </Flex>
            {/* <Box
                w={{ base: '90%', lg: '920px' }}
                h="2px"
                mx="auto"
                mt="124px"
                backgroundColor="#3C4453"
            ></Box>
            <Text
                w={{ base: '90%', lg: '920px' }}
                mx="auto"
                mt="22px"
                textAlign="right"
                color="#41476A"
                className={i_h3}
                fontSize="20px"
            >
                More features {width > 450 && 'of concentrated liquidity'} to
                come ...
            </Text> */}
        </Flex>
    );
};

export default HomePiece7;
