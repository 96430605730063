import {
    Flex,
    Menu,
    MenuButton,
    Text,
    useColorMode,
    VStack,
    Image,
    useOutsideClick,
    MenuList,
    MenuItem,
    HStack,
    Button,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Stack,
    Divider,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { links } from '../../config/links';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { i_text_copy, i_text_d } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';

export type TabBarProps = {
    config: any;
};

export const TabBar: React.FC<TabBarProps> = (pros) => {
    const { config } = pros;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const [isOpenList, setOpenList] = useState(Array(config.pages.length).fill(false));
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const getImgUrl = (img: string | undefined) => {
        return colorTheme(process.env.PUBLIC_URL + `/assets/tabBar/light${img}`, process.env.PUBLIC_URL + `/assets/tabBar/dark${img}`);
    };
    const getSelectedImgUrl = (img: string | undefined) => {
        return colorTheme(
            process.env.PUBLIC_URL + `/assets/tabBar/selected/light${img}`,
            process.env.PUBLIC_URL + `/assets/tabBar/selected/dark${img}`
        );
    };
    const history = useHistory();
    const listFocusRef = React.useRef<any | null>(null);
    useOutsideClick({
        ref: listFocusRef,
        handler: () => {
            const temp = Array(config.pages.length).fill(false);
            setOpenList(temp);
        },
    });
    let clickChildren = false;

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        const currentPath = location.pathname;
        config.pages.map((item: any, index: number) => {
            const isFounded = item.address === currentPath || item.children.find((i: any) => i.address === currentPath);
            if (isFounded) {
                setSelectedIndex(index);
            }
        });
    }, [config.pages, location]);

    function isIOSDevice() {
        return !!navigator.platform && /iPhone/.test(navigator.platform);
    }
    const showArray = config.pages.filter((page: any) => page.inTabBar === true);
    const ellipsisArray = config.pages.filter((page: any) => page.inTabBar === false);
    const showEllipsis = ellipsisArray.length > 0 ? true : false;

    return (
        <Flex
            w="100%"
            h={isIOSDevice() ? '97px' : '65px'}
            bgColor={colorTheme('#ffffff', '#1A282C')}
            zIndex="1"
            position="fixed"
            bottom="0px"
            ref={listFocusRef}
        >
            {showEllipsis ? (
                <HStack w="100%">
                    {showArray.map((page: any, index: number) => {
                        const isSelected = currentPath.toLowerCase().includes(page.address);
                        return (
                            <Flex
                                key={index}
                                w={'20%'}
                                h="37px"
                                onClick={() => {
                                    const temp = Array(config.pages.length).fill(false);
                                    temp[index] = !isOpenList[index];
                                    setOpenList(temp);
                                    if (page.address) {
                                        if (page.address.slice(0, 4) === 'http') {
                                            if (page.address === links.analyticLink) {
                                                window.open(page.address + '/Dashboard?chainId=' + chainId);
                                            } else {
                                                window.open(page.address);
                                            }
                                            return;
                                        }
                                        setSelectedIndex(index);
                                        if (!clickChildren) {
                                            history.push(page.address);
                                        }
                                    }
                                }}
                            >
                                <Menu isOpen={isOpenList[index]} key={index}>
                                    <MenuButton w="100%">
                                        <VStack>
                                            <Image
                                                boxSize="20px"
                                                src={isSelected ? getSelectedImgUrl(page.img) : getImgUrl(page.img)}
                                                fallbackSrc={getImgUrl(page.img)}
                                            ></Image>
                                            <Text
                                                className={i_text_copy}
                                                mt="3px !important"
                                                color={
                                                    isSelected ? colorTheme('#529B96', '#529B96') : colorTheme('tertiary.600', '#705C9A')
                                                }
                                            >
                                                {t(page.name)}
                                            </Text>
                                        </VStack>
                                    </MenuButton>
                                    {!page.address && (
                                        <MenuList
                                            minW="130px"
                                            w="100%"
                                            className={i_text_d}
                                            bg={colorTheme('#FBFBFB', '#100C1E')}
                                            position="relative"
                                            fontSize="13px"
                                        >
                                            {page.children.map((children: any, i: number) => {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        justifyContent="center"
                                                        onClick={() => {
                                                            setSelectedIndex(index);
                                                            if (children.address) {
                                                                if (children.address.slice(0, 4) === 'http') {
                                                                    window.open(children.address);
                                                                } else {
                                                                    if (page.address) {
                                                                        clickChildren = true;
                                                                    } else {
                                                                        clickChildren = false;
                                                                    }
                                                                    history.push(children.address);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Text>{t(children.name)}</Text>
                                                    </MenuItem>
                                                );
                                            })}
                                        </MenuList>
                                    )}
                                </Menu>
                            </Flex>
                        );
                    })}
                    <Popover>
                        <PopoverTrigger>
                            <Stack w={'20%'} h="100%" as={Button} variant="unstyled">
                                <Image w="100%" h="3px" src="/assets/tabBar/ellipsis.svg"></Image>
                            </Stack>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent maxW="180px" _focus={{ boxShadow: 'unset' }} border="unset" mb="-8px" zIndex="11">
                                <PopoverBody px="0px" py="20px">
                                    <Stack w="100%" px="25px" spacing="15px">
                                        {ellipsisArray.map((page: any, index: number) => {
                                            const isSelected = currentPath.toLowerCase().includes(page.address);
                                            const lastOne = index === ellipsisArray.length - 1;
                                            return (
                                                <Stack
                                                    key={index}
                                                    spacing="15px"
                                                    onClick={() => {
                                                        if (page.address) {
                                                            if (page.address.slice(0, 4) === 'http') {
                                                                if (page.address === links.analyticLink) {
                                                                    window.open(page.address + '/Dashboard?chainId=' + chainId);
                                                                } else {
                                                                    window.open(page.address);
                                                                }
                                                                return;
                                                            }
                                                            if (!clickChildren) {
                                                                history.push(page.address);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <HStack>
                                                        <Image
                                                            boxSize="20px"
                                                            src={isSelected ? getSelectedImgUrl(page.img) : getImgUrl(page.img)}
                                                            fallbackSrc={getImgUrl(page.img)}
                                                        ></Image>
                                                        <Text
                                                            className={i_text_copy}
                                                            color={
                                                                isSelected
                                                                    ? colorTheme('#529B96', '529B96')
                                                                    : colorTheme('tertiary.600', '#705C9A')
                                                            }
                                                        >
                                                            {t(page.name)}
                                                        </Text>
                                                    </HStack>
                                                    {!lastOne && <Divider></Divider>}
                                                </Stack>
                                            );
                                        })}
                                    </Stack>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </HStack>
            ) : (
                config.pages.map((page: any, index: number) => {
                    const isSelected = selectedIndex === index;

                    return (
                        <Flex
                            key={index}
                            w={100 / config.pages.length + '%'}
                            h="37px"
                            onClick={() => {
                                const temp = Array(config.pages.length).fill(false);
                                temp[index] = !isOpenList[index];
                                setOpenList(temp);
                                if (page.address) {
                                    if (page.address.slice(0, 4) === 'http') {
                                        if (page.address === links.analyticLink) {
                                            window.open(page.address + '/Dashboard?chainId=' + chainId);
                                        } else {
                                            window.open(page.address);
                                        }
                                        return;
                                    }
                                    setSelectedIndex(index);
                                    if (!clickChildren) {
                                        history.push(page.address);
                                    }
                                }
                            }}
                        >
                            <Menu isOpen={isOpenList[index]} key={index}>
                                <MenuButton w="100%">
                                    <VStack>
                                        <Image
                                            boxSize="20px"
                                            src={isSelected ? getSelectedImgUrl(page.img) : getImgUrl(page.img)}
                                            fallbackSrc={getImgUrl(page.img)}
                                        ></Image>
                                        <Text
                                            className={i_text_copy}
                                            mt="3px !important"
                                            color={isSelected ? colorTheme('#529B96', '#529B96') : colorTheme('tertiary.600', '#705C9A')}
                                        >
                                            {t(page.name)}
                                        </Text>
                                    </VStack>
                                </MenuButton>
                                {!page.address && (
                                    <MenuList
                                        minW="130px"
                                        w="100%"
                                        className={i_text_d}
                                        bg={colorTheme('#FBFBFB', '#100C1E')}
                                        position="relative"
                                        fontSize="13px"
                                    >
                                        {page.children.map((children: any, i: number) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    justifyContent="center"
                                                    onClick={() => {
                                                        setSelectedIndex(index);
                                                        if (children.address) {
                                                            if (children.address.slice(0, 4) === 'http') {
                                                                window.open(children.address);
                                                            } else {
                                                                if (page.address) {
                                                                    clickChildren = true;
                                                                } else {
                                                                    clickChildren = false;
                                                                }
                                                                history.push(children.address);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Text>{t(children.name)}</Text>
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                )}
                            </Menu>
                        </Flex>
                    );
                })
            )}
        </Flex>
    );
};
