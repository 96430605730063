import React from 'react';
import { BoxProps, ChakraProps, HStack, Stack, useBreakpointValue, useColorMode, VStack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy } from '../../../style';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { SupportFeeTiers } from '../../../config/trade/feeTiers';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import useIsMobile from '../../../hooks/useIsMobile';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import CustomButton from '../../../components/CustomButton/CustomButton';

export type FeesTiersSelectProps = {
    handleSelect: (fee: FeeTier) => void;
    currentFee: FeeTier;
    isAdvanceMode?: boolean;
    type?: AdaptationMode;
    textProps?: ChakraProps;
} & BoxProps;

const FeesTiersSelect: React.FC<FeesTiersSelectProps> = ({ handleSelect, currentFee, isAdvanceMode, type, textProps, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();
    const changeLine = SupportFeeTiers[chainId].length / 2;
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });
    const autoSpacing = SupportFeeTiers[chainId].length > 3 ? { base: '2px', xxl: '8px' } : '8px';

    const singleTier = (item: any) => {
        const isSelected = currentFee === item;
        return (
            <CustomButton
                key={item}
                variant={isMobile ? 'inlinePurple' : 'outlinePurple'}
                w={isMobile ? '25%' : 100 / SupportFeeTiers[chainId].length + '%'}
                h={isMobile ? '28px' : isAdvanceMode ? '34px' : { base: '44px', sm: '28px', xxl: '44px' }}
                selected={isSelected}
                onClick={() => handleSelect(item)}
                bgColor={'inherit'}
                borderColor={isSelected ? colorTheme('#21B0A6', '#21B0A6') : colorTheme('tertiary.200', 'tertiary.')}
                text={
                    <VStack alignItems="center" p="10px" spacing="4px" {...textProps}>
                        <Text
                            className={i_text_copy}
                            variant="caption"
                            fontSize={isAdvanceMode ? '12px !important' : '13px !important'}
                            color={isSelected ? colorTheme('tertiary.800', 'tertiary.200') : colorTheme('#8A7A96', 'tertiary.200')}
                        >
                            {isXXl ? Number(item).toFixed(2) + ' %' : Number(item).toFixed(2) + '%'}
                        </Text>
                    </VStack>
                }
                {...rest}
            />
        );
    };

    return type === 'mobile' ? (
        <Stack direction={'row'}>
            <HStack w="100%">
                {SupportFeeTiers[chainId].map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
        </Stack>
    ) : isMobile ? (
        <Stack direction={'row'} spacing={autoSpacing}>
            <HStack spacing={autoSpacing}>
                {SupportFeeTiers[chainId].slice(0, changeLine).map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
            <HStack spacing={autoSpacing}>
                {SupportFeeTiers[chainId].slice(changeLine, SupportFeeTiers[chainId].length).map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
        </Stack>
    ) : (
        <Stack w="100%" direction={'row'} spacing={autoSpacing}>
            <HStack w="100%" spacing={autoSpacing}>
                {SupportFeeTiers[chainId].map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
        </Stack>
    );
};

export default FeesTiersSelect;
