import { SearchIcon } from '@chakra-ui/icons';
import {
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorMode,
    VStack,
    Image,
    Text,
    HStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SEARCH_TOKENS_PRO } from '../../../../../../config/bizConfig';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { RootState } from '../../../../../../state/store';
import { i_text_copy_bold, noto_t3, noto_t3_bold } from '../../../../../../style';
import { getColorThemeSelector } from '../../../../../../utils/funcs';
import { ResponseKlineInfoMetaWithReverse } from '../../KlineInfo';

import FavoritesBlock from './FavoritesBlock';
import { useTokenListFormatted } from '../../../../../../hooks/useTokenListFormatted';
import SearchTokenBlock from './SearchTokenBlock';
import SearchResultBlock from './SearchResultBlock';
import { useTranslation } from 'react-i18next';
export enum SortKey {
    coins = 'Coins',
    price = 'Price',
    change24H = '24H Change',
}
export enum OrderKey {
    positive = 'Positive',
    reverse = 'Reverse',
    normal = 'Normal',
}
type SearchProps = {
    pools: ResponseKlineInfoMetaWithReverse[];
};
const Search: React.FC<SearchProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();
    const { tokenList } = useTokenListFormatted();

    const { pools } = props;
    const {
        favorite: { favPool },
    } = useSelector((state: RootState) => state);

    const [searchKey, setSearchKey] = useState('');

    const [filterKey, setFilterKey] = useState({ key: '' as SortKey, order: OrderKey.normal as OrderKey });

    const tabs = [
        {
            title: 'Coins',
            width: '200px',
        },
        {
            title: 'Price',
            width: '90px',
        },
        {
            title: '24H Change',
            width: '100px',
        },
    ];

    const tabStyle = {
        _selected: { color: '#7F4AFE', borderColor: '#7F4AFE', borderBottomWidth: '3px' },
        fontSize: '12px !important',
        px: '6px',
        mx: '5px',
        color: '#557371',
    };

    const filterBySortField = useMemo(() => {
        const filterNoName = pools.filter((item) => item.tokenX.symbol !== undefined && item.tokenY.symbol);

        if (filterNoName?.findIndex((item) => item.tokenX.symbol === undefined || item.tokenY.symbol === undefined) !== -1) {
            return filterNoName;
        }
        if (filterKey.key === SortKey.coins) {
            if (filterKey.order === OrderKey.positive) {
                return filterNoName.sort((a, b) => b.tokenX.symbol.localeCompare(a.tokenX.symbol));
            } else if (filterKey.order === OrderKey.reverse) {
                return filterNoName.sort((a, b) => a.tokenX.symbol.localeCompare(b.tokenX.symbol));
            } else {
                return filterNoName;
            }
        } else if (filterKey.key === SortKey.price) {
            if (filterKey.order === OrderKey.positive) {
                return filterNoName.sort((a, b) => b.pool.latestDealPrice - a.pool.latestDealPrice);
            } else if (filterKey.order === OrderKey.reverse) {
                return filterNoName.sort((a, b) => a.pool.latestDealPrice - b.pool.latestDealPrice);
            } else {
                return filterNoName;
            }
        } else if (filterKey.key === SortKey.change24H) {
            if (filterKey.order === OrderKey.positive) {
                return filterNoName.sort((a, b) => b.pool.dayPriceChange - a.pool.dayPriceChange);
            } else if (filterKey.order === OrderKey.reverse) {
                return filterNoName.sort((a, b) => a.pool.dayPriceChange - b.pool.dayPriceChange);
            } else {
                return filterNoName;
            }
        } else {
            return filterNoName;
        }
    }, [pools, filterKey.key, filterKey.order]);

    const filterPools = filterBySortField.filter((item) => {
        return (
            item.tokenX.symbol?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            item.tokenY.symbol?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    const favPoolKeys = Object.keys(favPool);
    const addr_list = favPoolKeys.map((k) => favPool[k].address);

    const favoritePools = filterBySortField.filter((item) => {
        return addr_list.includes(item.pool.address);
    });

    function filterByTokenY(tokenSymbol: string) {
        return filterBySortField.filter((item) => {
            return item.tokenY.symbol === tokenSymbol;
        });
    }

    const filterMoreTokens = filterBySortField.filter((item) => {
        return !SEARCH_TOKENS_PRO[chainId].includes(item);
    });

    const filterNameBlock = (
        <HStack spacing="0px" pl="23px" pr="5px" py="10px" position="sticky" top="0px">
            {tabs.map((item, index) => {
                return (
                    <HStack w={item.width} key={index} spacing="4px" justifyContent={item.title === 'Coins' ? 'start' : 'end'}>
                        <Text className={noto_t3} color={colorTheme('#767676', '#B3B3B3')}>
                            {t(item.title)}
                        </Text>

                        <VStack spacing="1px">
                            <Image
                                w="11px"
                                h="10px"
                                src={
                                    item.title === filterKey.key
                                        ? filterKey.order === OrderKey.normal
                                            ? '/assets/pro/search/normal.svg'
                                            : filterKey.order === OrderKey.positive
                                            ? '/assets/pro/search/positive.svg'
                                            : '/assets/pro/search/reverse.svg'
                                        : '/assets/pro/search/normal.svg'
                                }
                                fallbackSrc={'/assets/pro/search/normal.svg'}
                                cursor="pointer"
                                onClick={() => {
                                    if (item.title === SortKey.coins) {
                                        if (filterKey.key === SortKey.coins) {
                                            if (filterKey.order === OrderKey.normal) {
                                                setFilterKey({ ...filterKey, order: OrderKey.positive });
                                            } else if (filterKey.order === OrderKey.positive) {
                                                setFilterKey({ ...filterKey, order: OrderKey.reverse });
                                            } else {
                                                setFilterKey({ ...filterKey, order: OrderKey.normal });
                                            }
                                        } else {
                                            setFilterKey({ key: SortKey.coins, order: OrderKey.positive });
                                        }
                                    } else if (item.title === SortKey.price) {
                                        if (filterKey.key === SortKey.price) {
                                            if (filterKey.order === OrderKey.normal) {
                                                setFilterKey({ ...filterKey, order: OrderKey.positive });
                                            } else if (filterKey.order === OrderKey.positive) {
                                                setFilterKey({ ...filterKey, order: OrderKey.reverse });
                                            } else {
                                                setFilterKey({ ...filterKey, order: OrderKey.normal });
                                            }
                                        } else {
                                            setFilterKey({ key: SortKey.price, order: OrderKey.positive });
                                        }
                                    } else if (item.title === SortKey.change24H) {
                                        if (filterKey.key === SortKey.change24H) {
                                            if (filterKey.order === OrderKey.normal) {
                                                setFilterKey({ ...filterKey, order: OrderKey.positive });
                                            } else if (filterKey.order === OrderKey.positive) {
                                                setFilterKey({ ...filterKey, order: OrderKey.reverse });
                                            } else {
                                                setFilterKey({ ...filterKey, order: OrderKey.normal });
                                            }
                                        } else {
                                            setFilterKey({ key: SortKey.change24H, order: OrderKey.positive });
                                        }
                                    }
                                }}
                            ></Image>
                        </VStack>
                    </HStack>
                );
            })}
        </HStack>
    );

    return isMobile ? (
        <Stack py="20px" h="100%">
            <Stack w="100%" ml="16px">
                <InputGroup w="330px" h="35px">
                    <InputLeftElement boxSize="15px" mt="10px" ml="10px">
                        <SearchIcon opacity="0.5" />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="100%"
                        placeholder={t('Search Tokens or Pools')}
                        size="lg"
                        className={i_text_copy_bold}
                        fontSize="12px"
                        onChange={(e: any) => {
                            setSearchKey(e.target.value);
                        }}
                        color={colorTheme('#0B071E', '#EEEEEE')}
                        fontFamily="Montserrat-Bold"
                        letterSpacing="0.02em"
                        bg={colorTheme('#F8F8F9', '#0B071E')}
                        _focus={{ border: '1px solid #9264FF' }}
                        _placeholder={{ color: colorTheme('#999999', '#557371'), fontFamily: 'Montserrat' }}
                    />
                </InputGroup>
            </Stack>
            {searchKey ? (
                <Tabs _focus={{ border: '' }} _active={{}}>
                    {filterNameBlock}
                    <TabPanels
                        pl="17px"
                        pt="0px"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none !important',
                            },
                        }}
                        overflowY="auto"
                        h="290px"
                    >
                        <SearchResultBlock favoritePools={filterPools} tokenList={tokenList} pt="10px"></SearchResultBlock>
                    </TabPanels>
                </Tabs>
            ) : (
                <Tabs _focus={{ border: '' }} _active={{}}>
                    <TabList className={noto_t3_bold} bg={colorTheme('#ffffff', '#010303')} position="sticky" top="0px" zIndex="1">
                        <Tab ml="18px !important" pl="0px !important" {...tabStyle}>
                            <Image boxSize="16px" src="/assets/pro/info/star.svg" mr="4px"></Image>
                            {t('Favorites')}
                        </Tab>
                        {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                            return (
                                <Tab {...tabStyle} key={index}>
                                    {item}
                                </Tab>
                            );
                        })}

                        <Tab {...tabStyle}>More</Tab>
                    </TabList>

                    {filterNameBlock}
                    <TabPanels
                        px="5px"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none !important',
                            },
                        }}
                        overflowY="auto"
                        h="250px"
                    >
                        <TabPanel pt="0px">
                            <FavoritesBlock favoritePools={favoritePools} tokenList={tokenList}></FavoritesBlock>
                        </TabPanel>
                        {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                            return (
                                <TabPanel key={index} pt="0px">
                                    <SearchTokenBlock data={filterByTokenY(item)} tokenList={tokenList}></SearchTokenBlock>
                                </TabPanel>
                            );
                        })}

                        <TabPanel pt="0px">
                            <SearchTokenBlock data={filterMoreTokens} tokenList={tokenList}></SearchTokenBlock>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </Stack>
    ) : (
        <Stack py="20px" h="100%">
            <VStack w="100%">
                <InputGroup w="395px" h="35px">
                    <InputLeftElement boxSize="15px" mt="10px" ml="10px">
                        <SearchIcon opacity="0.5" />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="100%"
                        placeholder={t('Search Tokens or Pools')}
                        size="lg"
                        className={i_text_copy_bold}
                        fontSize="12px"
                        onChange={(e: any) => {
                            setSearchKey(e.target.value);
                        }}
                        color={colorTheme('#0B071E', '#EEEEEE')}
                        letterSpacing="0.02em"
                        fontFamily="Montserrat-Bold"
                        bg={colorTheme('#F8F8F9', '#0B071E')}
                        _focus={{ border: '1px solid #9264FF' }}
                        _placeholder={{ color: colorTheme('#999999', '#557371'), fontFamily: 'Montserrat' }}
                    />
                </InputGroup>
            </VStack>
            {searchKey ? (
                <Tabs _focus={{ border: '' }} _active={{}}>
                    {filterNameBlock}
                    <TabPanels
                        pl="17px"
                        pt="0px"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none !important',
                            },
                        }}
                        overflowY="auto"
                        h="290px"
                    >
                        <SearchResultBlock favoritePools={filterPools} tokenList={tokenList} pt="10px"></SearchResultBlock>
                    </TabPanels>
                </Tabs>
            ) : (
                <Tabs _focus={{ border: '' }} _active={{}}>
                    <TabList className={noto_t3_bold} bg={colorTheme('#ffffff', '#010303')} position="sticky" top="0px" zIndex="1">
                        <Tab ml="18px !important" pl="0px !important" {...tabStyle}>
                            <Image boxSize="16px" src="/assets/pro/info/star.svg" mr="4px"></Image>
                            Favorites
                        </Tab>
                        {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                            return (
                                <Tab {...tabStyle} key={index}>
                                    {item}
                                </Tab>
                            );
                        })}

                        <Tab {...tabStyle}>More</Tab>
                    </TabList>

                    {filterNameBlock}
                    <TabPanels
                        px="5px"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none !important',
                            },
                        }}
                        overflowY="auto"
                        h="250px"
                    >
                        <TabPanel pt="0px">
                            <FavoritesBlock favoritePools={favoritePools} tokenList={tokenList}></FavoritesBlock>
                        </TabPanel>
                        {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                            return (
                                <TabPanel key={index} pt="0px">
                                    <SearchTokenBlock data={filterByTokenY(item)} tokenList={tokenList}></SearchTokenBlock>
                                </TabPanel>
                            );
                        })}

                        <TabPanel pt="0px">
                            <SearchTokenBlock data={filterMoreTokens} tokenList={tokenList}></SearchTokenBlock>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            )}
        </Stack>
    );
};
export default Search;
