import { HStack, Stack, Text, VStack, Image, useColorMode, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { favInfoKey } from '../../../../../../state/models/pro/favorite/favorite';
import { RootState, RootDispatch } from '../../../../../../state/store';
import { noto_t3, noto_t3_bold } from '../../../../../../style';
import { getColorThemeSelector } from '../../../../../../utils/funcs';
import { formatNumber } from '../../../../../../utils/tokenMath';
import { ResponseKlineInfoMetaWithReverse } from '../../KlineInfo';
import { FavButton } from './FavButton';
import TokenEntry from './TokenEntry';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';

type FavoritesBlockProps = {
    favoritePools: ResponseKlineInfoMetaWithReverse[];
    tokenList: TokenInfoFormatted[];
};
const FavoritesBlock: React.FC<FavoritesBlockProps> = (props) => {
    const { favoritePools, tokenList } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { favorite } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const tabs = [
        {
            title: 'Coins',
            width: '200px',
        },
        {
            title: 'Price',
            width: '90px',
        },
        {
            title: '24H Change',
            width: '100px',
        },
    ];

    const FavBt = (favInfo: any) => {
        return (
            <FavButton
                w="20px"
                h="20px"
                variant="base"
                isFav={favInfoKey(favInfo) in favorite.favPool}
                setIsFav={(isAdd: boolean) =>
                    dispatch.favorite.modFavPool({
                        isAdd,
                        favInfo,
                    })
                }
                boxSize="15px"
                m="0!important"
                ml={{ base: 'auto !important', sm: '0px !important' }}
            />
        );
    };

    return (
        <Stack w="100%">
            <Stack spacing="12px">
                {favoritePools.length === 0 && (
                    <Center w="100%" mt="100px">
                        <Text className={noto_t3} color="#557371">
                            Oh, there's nothing here !
                        </Text>
                    </Center>
                )}
                {favoritePools.map((item, index) => {
                    return (
                        <HStack key={index} spacing="0px">
                            <HStack w={tabs[0].width} spacing="6px">
                                {FavBt({
                                    chainId: chainId,
                                    address: item.pool.address,
                                })}
                                <TokenEntry
                                    key={index}
                                    tokenList={tokenList}
                                    tokenX={item.tokenX}
                                    tokenY={item.tokenY}
                                    currentFee={item.pool.fee}
                                    pool={item.pool.address}
                                    isReverse={item.isReverse}
                                ></TokenEntry>
                            </HStack>
                            <Text className={noto_t3_bold} w={tabs[1].width} textAlign="end" color={colorTheme('#263B41', '#C9C3D7')}>
                                {formatNumber(item.pool.latestDealPrice, 2, 2, true)}
                            </Text>
                            <Text
                                className={noto_t3}
                                w={tabs[2].width}
                                textAlign="end"
                                color={item.pool.dayPriceChange >= 0 ? '#12931F' : '#CB1414'}
                            >
                                {item.pool.dayPriceChange >= 0 ? '+' : '-'}
                                {item.pool.dayPriceChange.toFixed(2)}
                                {'%'}
                            </Text>
                        </HStack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
export default FavoritesBlock;
