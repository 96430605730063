import { Center, Flex, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode } from '@chakra-ui/react';
import React, { useState } from 'react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { links } from '../../../../config/links';
import placeholder from '../../../../assets/placeholder.png';
import { i_h1, i_text_copy_bold, i_text_d } from '../../../../style';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { ChainId } from '../../../../types/mod';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHover } from '../../../../iZUMi-UI-toolkit/src/hooks/useHover';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import useLayoutType from '../../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../../state/models/pageLayout/pageLayout';
import { useHistory } from 'react-router-dom';

const HomePiece1: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const layoutType = useLayoutType();
    const history = useHistory();

    const isMobile = useIsMobile();
    const [ref, isHovering] = useHover<HTMLDivElement>();
    const [isOpenList, setOpenList] = useState(false);

    return (
        <Flex
            backgroundSize="cover"
            w="100%"
            h={{ base: 'unset', md: '548px', xl: layoutType === LayoutStateEnum.sidebar ? '620px' : '584px' }}
            p={{
                base: '125px 20px 0px 20px',
                xl: layoutType === LayoutStateEnum.sidebar ? '135px 114px 394px 114px' : '35px 114px 394px 114px',
            }}
            boxSizing="border-box"
            overflow="hidden"
        >
            <Flex
                w={{ base: '363px', md: '710px', xl: '967px' }}
                direction={{ base: 'column', md: 'row' }}
                margin="auto"
                alignItems="center"
                textAlign={{ base: 'left', xl: 'unset' }}
            >
                <Flex w={{ base: '100%', xl: '467px' }} mr="0px" flex={50} direction="column">
                    <HStack alignItems="start" justifyContent={{ base: 'center', sm: 'start' }} position="relative">
                        <HStack mr={{ base: '25px !important', sm: '0px !important' }}>
                            <Image
                                w={{ base: 'unset', lg: '85px' }}
                                h={{ base: '57px', md: '62px', lg: '89px' }}
                                src="/assets/home/iziLogo/logo.svg"
                                fallbackSrc={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg'}
                            ></Image>
                            <Text
                                className={i_h1}
                                w={{ base: 'unset', sm: '180px', md: '193px', lg: '174px' }}
                                color={colorTheme('#1D0928', '#F5F5F5')}
                                ml="15px !important"
                            >
                                iZiSwap
                            </Text>
                        </HStack>
                        <Center
                            w={{ base: '86px', sm: '110px' }}
                            h={{ base: '23px', sm: '32px' }}
                            bg={colorTheme('#ffffff', '#1D1B21')}
                            boxShadow={colorTheme('0px 0px 7px 4px rgba(238, 238, 238, 0.25)', '0px 0px 7px 4px rgba(19, 7, 7, 0.25)')}
                            borderRadius="37px"
                            ml={{ base: '-40px !important', sm: '-10px !important', xl: '-25px !important' }}
                            mt="-10px !important"
                            position={{ base: 'absolute', sm: 'unset' }}
                            top="-10px"
                            right="20px"
                            px="7px"
                            py="3px"
                        >
                            <Image
                                w={{ base: 'unset', md: '193px', lg: '82px' }}
                                h={{ base: '57px', md: '62px', lg: '21px' }}
                                src={colorTheme('/assets/home/mantle.svg', '/assets/home/darkMantle.svg')}
                                fallbackSrc={colorTheme('/assets/home/mantle.svg', '/assets/home/darkMantle.svg')}
                            ></Image>
                        </Center>
                    </HStack>
                    <Text className={i_text_d} mt="32px" color={colorTheme('#798280', '#F1F2F0')}>
                        {t(
                            'A Next-level Order Book Exchange on Mantle provides concentrated liquidity AMM and generates extra income for your asset'
                        )}
                    </Text>

                    <Flex w="100%" direction="row" mt={{ base: '30px', md: '45px' }} justifyContent={{ base: 'center', md: 'unset' }}>
                        <>
                            <CustomButton
                                w={{ base: '110px', md: '125px', xl: '178px' }}
                                h="40px"
                                variant="purple"
                                text={t('Go Trade')}
                                className={i_text_copy_bold}
                                bg={colorTheme('#529B96', '#21B0A6')}
                                _hover={{ opacity: '0.75' }}
                                onClick={() => {
                                    history.push('/swap');
                                }}
                                leftIcon={process.env.PUBLIC_URL + '/assets/home/whiteLogo.svg'}
                            ></CustomButton>

                            <CustomButton
                                w={{ base: '110px', md: '125px', xl: '178px' }}
                                h="40px"
                                // ml="10px"
                                ml={{ base: '10px', md: '13px', xl: '16px' }}
                                variant="primary3"
                                text={t('Add Liqudity')}
                                className={i_text_copy_bold}
                                bg={colorTheme('#EDF0EF', '#1A282C')}
                                _hover={{ opacity: '0.75' }}
                                onClick={async () => {
                                    history.push('/add-liquidity/popular');
                                }}
                            ></CustomButton>
                        </>
                    </Flex>
                </Flex>
                <Flex>
                    <Image
                        w={{ base: '308px', xl: '500px' }}
                        h={{ base: '308px', xl: '500px' }}
                        src={process.env.PUBLIC_URL + colorTheme('/assets/home/homepiece1.gif', '/assets/home/homepiece1.gif')}
                        fallbackSrc={placeholder}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default HomePiece1;
