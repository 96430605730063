import React, { useEffect, useState } from 'react';
import { Stack, Text, Image, useColorMode, HStack, Icon } from '@chakra-ui/react';
import useIsMobile from '../../hooks/useIsMobile';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_h2, i_text_copy } from '../../style';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import MyIPoints from './components/MyIPoints/MyIPoints';
import WeeklyReward from './components/WeeklyReward/WeeklyReward';
import Rules from './components/Rules/Rules';
import { getColorThemeSelector } from '../../utils/funcs';
import { getIPointsInfo, RequestIPointsInfo, ResponseIPointsInfo } from '../../net/iZUMi-endpoints/src/restful/izumiGame';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { useCustomToast } from '../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { links } from '../../config/links';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { link } from 'fs';
import { AiOutlineLink } from 'react-icons/ai';

const IPointsBlock: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { account, chainId } = useWeb3WithDefault();
    const toast = useCustomToast();
    const { t } = useTranslation();

    const [isShowAllHistory, setIsShowAllHistory] = useState(false);
    const [iPointsInfo, setIPointsInfo] = useState({} as ResponseIPointsInfo);
    const [networkLoading, setNetworkLoading] = useState(false);
    useEffect(() => {
        if (!account) {
            return;
        }
        setNetworkLoading(true);
        getIPointsInfo({
            address: account,
            chainId: chainId,
        } as RequestIPointsInfo)
            .then((e) => {
                const data = e.data.data;
                if (_.isEmpty(data)) {
                    return;
                }
                data.history.sort((a, b) => {
                    const timeA = parseInt(a.time, 10);
                    const timeB = parseInt(b.time, 10);
                    return timeB - timeA;
                });
                setIPointsInfo(e.data.data);
            })
            .catch((e) => {
                toast('error', e.message);
            })
            .finally(() => {
                setNetworkLoading(false);
            });
    }, [account, chainId]);
    return (
        <Stack spacing="10px">
            <Card
                w="100%"
                position="relative"
                overflow="hidden"
                bg={colorTheme('linear-gradient(89.43deg, #FDFEFF -5.79%, #FFFFFF -5.78%, #FBFBFF 101.17%)', '#151221')}
            >
                <Stack
                    w={{ base: '100%', sm: '579px' }}
                    h={{ base: 'unset', sm: '246px' }}
                    pl={{ base: '17px', sm: '33px' }}
                    pr={{ base: '17px', sm: '0px' }}
                    pt="45px"
                    pb="30px"
                >
                    <Stack h="100%" zIndex="1" spacing="12px">
                        <Text className={i_h2}>{t('iPoints')}</Text>
                        <Stack
                            spacing="0px"
                            className={i_text_copy}
                            fontSize="14px"
                            color={colorTheme('#443662', 'rgba(170, 168, 234, 0.59)')}
                            lineHeight="145%"
                        >
                            <Text>
                                {t('iZiSwap will share 50% of potential Mantle ecosystem ( Including a series of events such as ')}

                                <Text
                                    as="u"
                                    color={colorTheme('#0166FF', 'tertiary.300')}
                                    cursor="pointer"
                                    _hover={{ opacity: '0.7' }}
                                    onClick={() => {
                                        window.open(links.mantleJourney);
                                    }}
                                >
                                    <Icon as={AiOutlineLink} boxSize="17px" color={colorTheme('#0166FF', 'tertiary.300')} />
                                    Mantle Journey
                                </Text>
                                <Text as="span"> {')'} airdrop it gets in the future.</Text>
                            </Text>
                            <Text>{t('Swap and Add Liqudity will earn the iPoints.')}</Text>
                            <Text>{t('The more points you earn, the more airdrop you will be shared.')}</Text>
                        </Stack>

                        <CustomButton
                            w="117px"
                            h="31px"
                            text={t('Learn More')}
                            bg="#4F54CF"
                            color="#FFFFFF"
                            className={i_text_copy}
                            borderRadius="4px"
                            _hover={{ bg: '#8C8DFC' }}
                            mt={{ base: '20px !important', sm: 'auto !important' }}
                            onClick={() => {
                                window.open(links.iPointsAnnouncement);
                            }}
                        ></CustomButton>
                    </Stack>
                </Stack>
                <Image
                    position="absolute"
                    right="0px"
                    bottom="-10px"
                    w="250px"
                    h="250px"
                    src={colorTheme('/assets/iPoints/header/gift.svg', '/assets/iPoints/header/darkGift.svg')}
                    fallbackSrc={colorTheme('/assets/iPoints/header/gift.svg', '/assets/iPoints/header/darkGift.svg')}
                ></Image>
                <Image
                    position="absolute"
                    right="240px"
                    top="10px"
                    w="90px"
                    h="90px"
                    src={colorTheme('/assets/iPoints/header/izi.svg', '/assets/iPoints/header/darkIZI.svg')}
                    fallbackSrc={colorTheme('/assets/iPoints/header/izi.svg', '/assets/iPoints/header/darkIZI.svg')}
                ></Image>
                <Image
                    position="absolute"
                    right="270px"
                    bottom="10px"
                    w="80px"
                    h="80px"
                    src={colorTheme('/assets/iPoints/header/virtualIZI.svg', '/assets/iPoints/header/darkVirtualIZI.svg')}
                    fallbackSrc={colorTheme('/assets/iPoints/header/virtualIZI.svg', '/assets/iPoints/header/darkVirtualIZI.svg')}
                ></Image>
            </Card>
            <Stack alignItems="start" spacing="16px" direction={{ base: 'column', sm: 'row' }}>
                <Stack w={{ base: '100%', sm: 'unset' }} spacing="17px">
                    <MyIPoints
                        networkLoading={networkLoading}
                        iPointsInfo={iPointsInfo}
                        isShowAllHistory={isShowAllHistory}
                        setIsShowAllHistory={setIsShowAllHistory}
                    ></MyIPoints>
                    {(!isShowAllHistory || isMobile) && (
                        <WeeklyReward networkLoading={networkLoading} iPointsInfo={iPointsInfo}></WeeklyReward>
                    )}
                </Stack>
                <Rules></Rules>
            </Stack>
        </Stack>
    );
};

export default IPointsBlock;
