import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.Mantle]: [
        {
            titleImgSrc: baseURL + 'mantle.png',
            title: 'Mantle Bridge',
            content: "Mantle's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.mantle.xyz/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?amountIn=600&chainIn=Mantle&chainOut=ZkSync%20Era&tokenIn=MNT&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Cross-chain technology aggregator + Tools to enable it for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=MANTLE&toChain=ZK_SYNC&from=MNT&to=iZi',
        },
        // {
        //     titleImgSrc: baseURL + 'cbridge.png',
        //     title: 'cBridge',
        //     content:
        //         'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
        //     link: 'https://cbridge.celer.network/1/324/USDC',
        // },
    ],
    [ChainId.MantleTest]: [
        {
            titleImgSrc: baseURL + 'mantle.png',
            title: 'Bridge',
            content: "Mantle's official testnet bridge that can securely bridge Goerli's native assets in the safest way possible.",
            link: 'https://bridge.testnet.mantle.xyz/',
        },
        {
            titleImgSrc: baseURL + 'mantle.png',
            title: 'Faucet',
            content: "Mantle's testnet ERC20 Faucet. Drip here non-valuable tokens to build or interact with Mantle Goerli Testnet dApps.",
            link: 'https://faucet.testnet.mantle.xyz/',
        },
    ],
} as any;
