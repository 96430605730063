import React from 'react';
import { Flex, Text, Box, useColorMode, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useHover } from '../../../hooks/useHover';
import './NavButton.css';
import { getColorThemeSelector } from '../../../utils/funcs';
import placeholder from '../../../assets/placeholder.png';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { a_display } from '../../../iZUMi-UI-toolkit/src/style';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

interface Props {
    page: PageConfig;
    isSelected: boolean;
    children?: React.ReactNode;
    width?: string;
    disableLink?: boolean;
    showNav?: boolean;
    img?: string;
}

const NavButton: React.FC<Props> = ({ page, img, width, isSelected: isSelectedInitial, children, disableLink, showNav }) => {
    const [ref, isHovering] = useHover<HTMLDivElement>();
    //const isSelected = isSelectedInitial || isHovering;
    const isSelected = isSelectedInitial;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const history = useHistory();
    const { chainId } = useWeb3WithDefault();

    const textStyle = colorTheme(
        {
            color: '#15201F',
        },
        {
            color: '#B0D7CB',
        }
    );

    const textStyleSelect = colorTheme(
        {
            //bgImg: 'linear-gradient(98.73deg, #1F0C4E -2.37%, #4F1FB4 89.44%)',
            //bgClip: 'text',
            color: '#529B96',
        },
        {
            color: '#FFFFFF',
        }
    );

    const textStyleHover = colorTheme(
        {
            color: '#6E608F',
        },
        {
            color: '#D6D0E5',
        }
    );

    const [endTextStyle] = (() => {
        if (isSelected) {
            return [textStyleSelect];
        } else if (isHovering) {
            return [textStyleHover];
        } else {
            return [textStyle];
        }
    })();

    const opacity = (() => {
        if (isSelected) {
            return 1;
        } else if (isHovering) {
            return 0.8;
        } else {
            return 0.5;
        }
    })();

    const imgUrl = isSelected
        ? process.env.PUBLIC_URL + `/assets/sidebar/select/select${img}`
        : colorTheme(process.env.PUBLIC_URL + `/assets/sidebar/light${img}`, process.env.PUBLIC_URL + `/assets/sidebar/dark${img}`);

    const button = (
        <Flex
            direction="row"
            align="center"
            minH="50px"
            pos="relative"
            boxSizing="border-box"
            pl="36px"
            ref={ref}
            width={width}
            //className="trans"
            position="relative"
        >
            {
                //Icon !== undefined ? <Icon isHovering={isSelected} /> : undefined}
            }
            <Image
                display="inline"
                ml="4px"
                w="20px"
                h="20px"
                // opacity={opacity}
                transitionProperty="all"
                transitionDuration="0.2s"
                transitionDelay="0.3s"
                src={imgUrl}
                fallbackSrc={placeholder}
            ></Image>

            <Text
                ml="24px"
                flex={1}
                fontFamily="Montserrat-SemiBold"
                //fontWeight="bold"
                letterSpacing="-0.02em"
                fontSize="13px"
                textAlign="left"
                display="flex"
                flexDir="row"
                //className="trans"
                whiteSpace="nowrap"
                transitionProperty="all"
                transitionDuration="0.2s"
                transitionTimingFunction="ease"
                transitionDelay={showNav ? '0.3s' : '0s'}
                // {...(showNav ? endTextStyle : { color: 'transparent' })}
                color={isSelected ? colorTheme('#529B96', '#21B0A6') : colorTheme('#15201F', '#B0D7CB')}
            >
                {showNav ? t(page.name) : ''}
                <Box
                    as="span"
                    display="inline-block"
                    pos="absolute"
                    right={showNav ? '19px' : '8px'}
                    top={showNav ? '12px' : '12px'}
                    color="#9F9F9F"
                >
                    {children}
                </Box>
            </Text>
        </Flex>
    );
    const extralLinkButton = (link: string) => {
        return (
            <Flex
                direction="row"
                align="center"
                minH="50px"
                pos="relative"
                boxSizing="border-box"
                pl="36px"
                ref={ref}
                width={width}
                //className="trans"
                position="relative"
                onClick={() => {
                    if (page.address && page.address.startsWith('http')) {
                        if (page.address === links.analyticLink) {
                            window.open(page.address + '/Dashboard?chainId=' + chainId);
                        } else {
                            window.open(page.address);
                        }
                    } else {
                        history.push(page.address as any);
                    }
                }}
            >
                {
                    //Icon !== undefined ? <Icon isHovering={isSelected} /> : undefined}
                }
                <Image
                    display="inline"
                    ml="4px"
                    w="20px"
                    h="20px"
                    // opacity={opacity}
                    transitionProperty="all"
                    transitionDuration="0.2s"
                    transitionDelay="0.3s"
                    src={imgUrl}
                    fallbackSrc={placeholder}
                ></Image>

                <Text
                    ml="24px"
                    flex={1}
                    fontFamily="Montserrat-SemiBold"
                    //fontWeight="bold"
                    letterSpacing="-0.02em"
                    fontSize="13px"
                    textAlign="left"
                    display="flex"
                    flexDir="row"
                    //className="trans"
                    whiteSpace="nowrap"
                    transitionProperty="all"
                    transitionDuration="0.2s"
                    transitionTimingFunction="ease"
                    transitionDelay={showNav ? '0.3s' : '0s'}
                    // {...(showNav ? endTextStyle : { color: 'transparent' })}
                    color={isSelected ? colorTheme('#529B96', '#21B0A6') : colorTheme('#15201F', '#B0D7CB')}
                >
                    {showNav ? t(page.name) : ''}
                    <Box
                        as="span"
                        display="inline-block"
                        pos="absolute"
                        right={showNav ? '19px' : '8px'}
                        top={showNav ? '12px' : '12px'}
                        color="#9F9F9F"
                    >
                        {children}
                    </Box>
                </Text>
            </Flex>
        );
    };

    let endButton = button;

    if (!disableLink) {
        page.address?.startsWith('http')
            ? (endButton = extralLinkButton(page.address))
            : (endButton = <Link to={page.address!}>{button}</Link>);
    }

    return endButton;
};

export default React.memo(NavButton);
