import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { VStack, HStack, Box, Image, useColorMode, Stack, Divider, Flex, Circle, useBreakpointValue } from '@chakra-ui/react';

import IconTool from '../components/IconTool';
import { isNumeric } from '../../../utils/valid';
import { Loading } from '../../components/Loading';
import { getGasToken } from '../../../config/tokens';
import useIsMobile from '../../../hooks/useIsMobile';
import { formatNumber } from '../../../utils/tokenMath';
import placeholder from '../../../assets/placeholder.png';
import FeesTiersSelect from '../components/FeesTiersSelect';
import TokenAmountInput from '../components/TokenAmountInput';
import CurrentPriceCard from '../components/CurrentPriceCard';
import { RootDispatch, RootState } from '../../../state/store';
import { TokenSelectButton } from '../components/TokenSelectButton';
import { getColorThemeSelector } from '../../../utils/funcs';
import { ToastLink, useCustomToast } from '../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { i_h2, i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { LIMIT_ORDER_MANAGER_ADDRESSES } from '../../../config/trade/tradeContracts';
import { useLimitOrderManagerContract } from '../../../hooks/useContracts';
import { AddLimitOrderParams } from '../../../state/models/trade/limitOrder/tradeLimitOrder';
import { validateLimitOrderForm } from '../../../state/models/trade/limitOrder/funcs';
import { getFieldValidateInfo } from '../../../state/models/trade/liquidity/validationHelper';
import useTokenEntity from '../../../state/models/hooks/useTokenEntity';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { CustomNumberInput } from '../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import { AmountInput } from '../components/AmountInput';
import { ErrorInfo } from '../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import { fetchPoolState } from '../../../state/models/trade/pools/controllers';
import { point2PriceDecimal } from '../../../state/models/trade/utils/priceMath';
import { PoolState } from '../../../state/models/trade/pools/types';
import { point2PriceUndecimal } from '../../../state/models/farm/iZiSwap/price';
import { CUSTOM_TOKEN_BLACKLIST, LIMIT_ORDER_DISABLE_CHAIN, TRADE_DEFAULT_TO_TOKEN } from '../../../config/bizConfig';
import { getChain, getTxLink } from '../../../config/chains';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useGasPrice } from '../../../hooks/useGasPrice';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { TradeLoadingModal } from '../../../components/Loading/TradeLoadingModal';
import { useTradeLoading } from '../../../hooks/useTradeLoading';

type LimitFormProps = {
    type?: AdaptationMode;
};

const LimitForm: React.FC<LimitFormProps> = (props) => {
    const { type } = props;
    const { t } = useTranslation();
    const { tradeMode } = useSelector((state: RootState) => state);
    const isMobile = useIsMobile();
    const isSimpleMode = tradeMode.isSimpleMode;
    const history = useHistory();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });

    const { chainId, web3, account } = useWeb3WithDefault();
    const { tradeLimitOrder } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const limitOrderMgrContract = useLimitOrderManagerContract();
    const { loading, tokenList } = useTokenListFormatted();
    const { tradeState, setInitTradeState, setTradeStateIfNotSubmitted, setTradeStateIfNotSuccess } = useTradeLoading();
    const { gasPrice } = useGasPrice();

    const { limitOrderForm } = tradeLimitOrder;
    const limitOrderMgrContractAddress = LIMIT_ORDER_MANAGER_ADDRESSES[chainId];

    const tokenXEntity = useTokenEntity(limitOrderForm.tokenX, limitOrderMgrContractAddress);
    const tokenYEntity = useTokenEntity(limitOrderForm.tokenY, limitOrderMgrContractAddress);
    const tokenXApproved = tokenXEntity.isApproved(String(limitOrderForm.amount));

    const [poolPrice, setPoolPrice] = useState<number>(0);
    const [poolPriceDecimal, setPoolPriceDecimal] = useState<number>(0);

    const [isInputFromFocus, setIsInputFromFocus] = useState(false);
    const [isInputToFocus, setIsInputToFocus] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [toastLink, setToastLink] = useState({} as ToastLink);

    const toast = useCustomToast();
    const searchParams = useSearchParams();

    useEffect(() => {
        dispatch.tradeLimitOrder.clearLimitOrderForm();
    }, [chainId]);

    // Auto fill token if has params in URL
    useEffect(() => {
        if (!loading) {
            const chainIdFromUrl = searchParams.get('chainId');
            // chainId not match
            if (chainIdFromUrl && Number(chainIdFromUrl) !== Number(chainId)) {
                //TODO: toast not match chainId
                return;
            }

            const tokenFromAddr = searchParams.get('tokenFrom');

            if (tokenFromAddr && tokenFromAddr !== 'GasToken') {
                const tokenFromFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenFromAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenFromAddr.toUpperCase()
                    ),
                };
                if (
                    !tokenFromFormatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === tokenFromAddr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: tokenFromAddr,
                        chainId,
                        web3,
                    });
                }
            }

            const tokenToAddr = searchParams.get('tokenTo');

            if (tokenToAddr && tokenToAddr !== 'GasToken') {
                const tokenToFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenToAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenToAddr.toUpperCase()
                    ),
                };
                if (
                    !tokenToFormatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === tokenToAddr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: tokenToAddr,
                        chainId,
                        web3,
                    });
                }
            }
        }
    }, [searchParams, tokenList, loading, dispatch, chainId, web3]);

    useEffect(() => {
        if (!chainId || limitOrderForm.tokenX.symbol || limitOrderForm.tokenY.symbol || limitOrderForm.fee) {
            return;
        }
        //dispatch.tradeLimitOrder.setLimitOrderFormFee(fee)
        //const chainIdFromUrl = searchParams.get('chainId');
        //const chainMatch = !chainIdFromUrl || (chainIdFromUrl && Number(chainIdFromUrl) === chainId);

        const tokenFromAddr = searchParams.get('tokenFrom');
        const tokenToAddr = searchParams.get('tokenTo');
        const fee = Number(searchParams.get('fee')) / 10000;

        let tokenFromFormatted = undefined;
        let tokenToFormatted = undefined;

        if (tokenFromAddr) {
            if (tokenFromAddr !== 'GasToken') {
                tokenFromFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenFromAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenFromAddr.toUpperCase()
                    ),
                };
            } else {
                tokenFromFormatted = getGasToken(chainId);
            }
        } else {
            tokenFromFormatted = getGasToken(chainId);
        }

        if (tokenToAddr) {
            if (tokenToAddr !== 'GasToken') {
                tokenToFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenToAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenToAddr.toUpperCase()
                    ),
                };
            } else {
                tokenToFormatted = getGasToken(chainId);
            }
        } else {
            tokenToFormatted = tokenList.find((e) =>
                Array.isArray(TRADE_DEFAULT_TO_TOKEN[chainId])
                    ? e.symbol === TRADE_DEFAULT_TO_TOKEN[chainId][0] &&
                      e.address.toUpperCase() === TRADE_DEFAULT_TO_TOKEN[chainId][1].toUpperCase()
                    : e.symbol === (TRADE_DEFAULT_TO_TOKEN[chainId] ?? 'iZi')
            ) as unknown as TokenInfoFormatted;
        }

        if (!loading && tokenList.length) {
            dispatch.tradeLimitOrder.setLimitOrderFormToken({
                isUpper: true,
                chainId,
                //tokenInfo: tokenList.find((e) => e.symbol === (TRADE_DEFAULT_TO_TOKEN[chainId] ?? 'iZi')) as unknown as TokenInfoFormatted,
                tokenInfo: tokenToFormatted as any,
            });
        }
        if (!loading && tokenList.length) {
            dispatch.tradeLimitOrder.setLimitOrderFormToken({
                isUpper: false,
                chainId,
                //tokenInfo: getGasToken(chainId),
                tokenInfo: tokenFromFormatted as any,
            });
        }

        if (!loading && tokenList.length) {
            dispatch.tradeLimitOrder.setLimitOrderFormFee((fee > 0 ? fee : 0.2) as FeeTier);
        }
    }, [searchParams, chainId, dispatch.tradeLimitOrder, limitOrderForm, loading, tokenList]);

    useEffect(() => {
        if (!limitOrderForm.tokenX || !limitOrderForm.tokenY || !limitOrderForm.fee || !chainId) {
            setPoolPrice(0);
            return;
        }
        dispatch.tradeLimitOrder.setPoolStatusLoading(true);

        fetchPoolState({
            web3,
            chainId,
            baseContract: limitOrderMgrContract,
            tokenA: limitOrderForm.tokenX,
            tokenB: limitOrderForm.tokenY,
            fee: limitOrderForm.fee,
        }).then((r: PoolState) => {
            if (r) {
                const poolPriceDecimal = point2PriceDecimal(limitOrderForm.tokenX, limitOrderForm.tokenY, Number(r.currentPoint));
                const poolPriceUndecimal = point2PriceUndecimal(limitOrderForm.tokenX, limitOrderForm.tokenY, Number(r.currentPoint));
                setPoolPriceDecimal(poolPriceDecimal);
                setPoolPrice(Number(poolPriceUndecimal));
            } else {
                setPoolPrice(0);
            }
            dispatch.tradeLimitOrder.setPoolStatusLoading(false);
        });
    }, [limitOrderMgrContract, limitOrderForm.tokenX, limitOrderForm.tokenY, limitOrderForm.fee, chainId, web3]);

    const validateResult = validateLimitOrderForm(limitOrderForm, tokenXEntity.tokenBalance(), poolPriceDecimal);
    const isShowError = !isInputFromFocus && validateResult && poolPrice !== 0;

    const marketPrice = [t('Market Price')];

    const poolNotExisted = (
        <VStack alignItems="center" h="100%">
            <VStack my="auto">
                <Image
                    w={{ base: '107px', sm: isSimpleMode ? '250px' : '107px' }}
                    h={{ base: '84px', sm: isSimpleMode ? '210px' : '84px' }}
                    src={process.env.PUBLIC_URL + colorTheme('/assets/trade/lightNotConnect.png', '/assets/trade/darkNotConnect.png')}
                    fallbackSrc={placeholder}
                />
                <Text w="250px" variant="caption" textAlign="center" color={colorTheme('tertiary.600', 'tertiary.200')}>
                    {t('Oops! The pool does not exist, please try another fee tiers')}
                </Text>
            </VStack>
        </VStack>
    );

    const priceToolTip = (
        <HStack
            w="100%"
            justifyContent="center"
            py="6px"
            bg={colorTheme('linear-gradient(90.3deg, #F3EFFF -0.17%, #F8EEFF 113.22%);', '#010303')}
            mt={{ base: '12px !important', sm: '10px !important', xxl: '12px !important' }}
            borderRadius="4px"
        >
            <Circle w="6px" h="6px" bg="#784DF5"></Circle>
            <Text variant="caption" className={i_text_copy_bold} color={colorTheme('#5D2EA8', 'rgba(223, 202, 255, 0.68)')}>
                {isXXl ? t('Equals to buy at') : 'Equals: buy'}
                <Text variant="caption" as="span" color={colorTheme('#370A80', '#D3B8FF')} fontWeight="600" ml="3px">
                    {' '}
                    1{' '}
                </Text>
                <Text variant="caption" as="span">
                    {limitOrderForm.tokenY.symbol}
                </Text>
                <Text variant="caption" as="span">
                    {' '}
                    ={' '}
                </Text>
                <Text variant="caption" as="span" color={colorTheme('#370A80', '#D3B8FF')} fontWeight="600">
                    {limitOrderForm.sellPriceDecimal ? formatNumber(1 / limitOrderForm.sellPriceDecimal, 2, 2, true) : '??'}{' '}
                </Text>
                <Text variant="caption" as="span">
                    {limitOrderForm.tokenX.symbol}
                </Text>
            </Text>
        </HStack>
    );

    const AmountInputX = (
        <VStack spacing="10px" alignItems="flex-start" w={'100%'} px="0px">
            <TokenAmountInput
                type="mobile"
                border={
                    isInputFromFocus
                        ? '2px solid #529B96'
                        : isShowError
                        ? '2px solid #E05757'
                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                }
                borderRadius="6px"
                titleProps={{ p: 0, fontSize: '12px' }}
                h={{ base: 'unset', sm: isSimpleMode ? '132px' : 'unset' }}
                handleSetValue={(value: any) => {
                    isNumeric(value) && dispatch.tradeLimitOrder.setLimitOrderFormAmount(value);

                    const rate = 1;
                    dispatch.tradeLimitOrder.setSellPrice({
                        sellPriceDecimal: Number(rate * poolPriceDecimal),
                        fee: limitOrderForm.fee,
                    });
                }}
                token={limitOrderForm.tokenX}
                price={tokenXEntity.tokenPrice()}
                balance={tokenXEntity.tokenBalance()}
                // errorInfo={getFieldValidateInfo(validateResult, 'amount')}
                inputValue={limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5)}
                setIsInputFocus={setIsInputFromFocus}
            ></TokenAmountInput>
        </VStack>
    );
    const priceInput = (
        <VStack spacing="10px" alignItems="flex-start" w={'100%'} px="0px">
            <HStack w="100%" justifyContent="space-between">
                <Text
                    variant="caption"
                    className={i_text_copy}
                    fontFamily="Montserrat"
                    fontWeight="bold"
                    color={colorTheme('#8D909E', 'tertiary.200')}
                >
                    {t('Price')}
                </Text>
            </HStack>

            <Card
                variant="lightGary"
                w="100%"
                h="auto"
                pt="3px"
                pr="15px"
                pl="18px"
                border={isInputToFocus ? '2px solid #529B96' : colorTheme('2px solid #ffffff', '2px solid #211834')}
            >
                <VStack spacing="0" w="100%" h="100%">
                    <HStack spacing="5px" w="100%" h={{ base: '35px', sm: '25px', xxl: '35px' }} pt="9px" justifyContent="space-between">
                        <Text
                            variant="caption-bold"
                            className={i_text_copy}
                            fontFamily="Montserrat"
                            fontWeight="bold"
                            color={colorTheme('#8D909E', 'tertiary.200')}
                            pl="8px"
                            whiteSpace="nowrap"
                        >
                            1 {limitOrderForm.tokenX.symbol} =
                        </Text>
                        <HStack>
                            <CustomNumberInput
                                type="mobile"
                                onBlur={(value: any) => {
                                    // TODO fee
                                    isNumeric(value) &&
                                        dispatch.tradeLimitOrder.setSellPrice({
                                            sellPriceDecimal: value,
                                            fee: limitOrderForm.fee,
                                        });
                                }}
                                // errorInfo={getFieldValidateInfo(validateResult, 'sellPrice')}
                                inputValue={limitOrderForm.sellPriceDecimal && new BigNumber(limitOrderForm.sellPriceDecimal).toFixed(5)}
                                fontClass={i_h2}
                                setIsInputFocus={setIsInputToFocus}
                            />
                            <Text
                                variant="caption"
                                className={i_text_copy_bold}
                                fontSize="12px"
                                color={colorTheme('tertiary.800', 'tertiary.200')}
                                ml="0px !important"
                            >
                                {limitOrderForm.tokenY.symbol}
                            </Text>
                        </HStack>
                    </HStack>
                    <Stack w="100%" alignItems="end">
                        <Text variant="caption" color={colorTheme('tertiary.300', 'tertiary.400')} textAlign="center" mt="0px !important">
                            ≈{' '}
                            {formatNumber(
                                (limitOrderForm.sellPriceDecimal ?? 0) * (limitOrderForm.amountDecimal ?? 0) * tokenYEntity.tokenPrice()
                            )}{' '}
                            USD
                        </Text>
                    </Stack>
                    <HStack spacing="5px" w="100%" mb="12px" pb="12px" justifyContent={isSimpleMode ? 'unset' : 'end'}>
                        <HStack ml="auto !important" mt="6px">
                            {marketPrice.map((item, index) => (
                                <CustomButton
                                    onClick={() => {
                                        const rate = item === t('Market Price') ? 1 : Number(item) / 100 + 1;
                                        dispatch.tradeLimitOrder.setSellPrice({
                                            sellPriceDecimal: Number(rate * poolPriceDecimal),
                                            fee: limitOrderForm.fee,
                                        });
                                    }}
                                    py="auto"
                                    px="10px"
                                    key={index}
                                    h="22px"
                                    borderRadius="3px"
                                    alignItems="center"
                                    justifyContent="center"
                                    variant={item === t('Market Price') ? 'tertiary' : 'primary2'}
                                    text={typeof item === 'number' ? `+${item}%` : item}
                                    fontClass={i_text_d}
                                    fontSize="12px"
                                    bgColor={item === t('Clear') ? colorTheme('#F6EAEA', '#68466D') : colorTheme('#ECF1F7', '#5B5692')}
                                    color={item === t('Clear') ? colorTheme('#816D6F', '#EDB2B8') : colorTheme('#636A7D', '#BBB0FF')}
                                />
                            ))}
                        </HStack>
                    </HStack>
                </VStack>
            </Card>
        </VStack>
    );

    const limitOrderInput = (
        <VStack>
            <VStack
                spacing={isSimpleMode ? { base: '10px', sm: '6px', xxl: '10px' } : '10px'}
                alignItems="flex-start"
                w={isMobile ? '90%' : isSimpleMode ? { base: '326px', sm: '259px', xxl: '326px' } : '326px'}
                px="0px"
            >
                <HStack w="100%" justifyContent="space-between">
                    <Text variant="caption" fontWeight="bold" color={colorTheme('tertiary.600', 'tertiary.200')}>
                        {t('Amount')}
                    </Text>
                    <HStack display={isSimpleMode ? 'none' : 'block'}>
                        <Text variant="display" className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.300')}>
                            {t('Balance')}
                        </Text>
                        <Text
                            variant="display"
                            className={i_text_copy}
                            fontSize="16px"
                            color={colorTheme('tertiary.500', 'tertiary.300')}
                            whiteSpace="nowrap"
                        >
                            {formatNumber(Number(tokenXEntity.tokenBalance()))}
                        </Text>
                    </HStack>
                </HStack>

                <TokenAmountInput
                    border={
                        isInputFromFocus
                            ? '2px solid #529B96'
                            : isShowError
                            ? '2px solid #E05757'
                            : colorTheme('2px solid #ffffff', '2px solid #211834')
                    }
                    borderRadius="6px"
                    titleProps={{ p: '0px', fontSize: '12px' }}
                    h={isSimpleMode ? { base: '140px', sm: '108px', xxl: '140px' } : 'unset'}
                    handleSetValue={(value: any) => {
                        isNumeric(value) && dispatch.tradeLimitOrder.setLimitOrderFormAmount(value);
                    }}
                    token={limitOrderForm.tokenX}
                    price={tokenXEntity.tokenPrice()}
                    balance={tokenXEntity.tokenBalance()}
                    // errorInfo={getFieldValidateInfo(validateResult, 'amount')}
                    inputValue={limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5)}
                    setIsInputFocus={setIsInputFromFocus}
                    iconProps={
                        isSimpleMode ? { w: { base: '24px', sm: '20px', xxl: '24px' }, h: { base: '24px', sm: '20px', xxl: '24px' } } : {}
                    }
                    symbolProps={isSimpleMode ? { fontSize: { base: '18px', sm: '16px', xxl: '18px' } } : {}}
                    inputProps={isSimpleMode ? { h: { base: '50px', sm: '32px', xxl: '50px' } } : {}}
                    balanceProps={
                        isSimpleMode
                            ? {
                                  fontSize: { base: '16px', sm: '12px', xxl: '16px' },
                                  lineHeight: { base: '24px', sm: '12px', xxl: '24px' },
                                  fontWeight: { base: '500', sm: '600', xxl: '500' },
                              }
                            : {}
                    }
                    toolProps={{ mt: { base: '0px !important', sm: '2px !important', xxl: '0px !important' } }}
                />
            </VStack>

            <VStack
                spacing={isSimpleMode ? { base: '10px', sm: '6px', xxl: '10px' } : '10px'}
                alignItems="flex-start"
                w={isMobile ? '90%' : isSimpleMode ? { base: '326px', sm: '259px', xxl: '326px' } : '326px'}
                px="0px"
            >
                <HStack w="100%" justifyContent="space-between">
                    <Text variant="caption" fontWeight="bold" color={colorTheme('tertiary.600', '#F5F5F5')}>
                        {t('Price')}
                    </Text>
                </HStack>

                <Card
                    variant="lightGary"
                    w="100%"
                    h={isSimpleMode ? { base: '130px', sm: '102px', xxl: '130px' } : 'auto'}
                    pt="3px"
                    pr="15px"
                    pl="18px"
                    border={isInputToFocus ? '2px solid #529B96' : colorTheme('2px solid #ffffff', '2px solid #211834')}
                >
                    <VStack spacing="0" w="100%" h="100%">
                        <HStack
                            spacing="5px"
                            w="100%"
                            h={{ base: '35px', sm: '25px', xxl: '35px' }}
                            pt={isSimpleMode ? { base: '15px', sm: '9px', xxl: '15px' } : '15px'}
                            justifyContent="space-between"
                        >
                            <Text
                                fontSize="15px"
                                lineHeight="17px"
                                color={colorTheme('tertiary.700', 'tertiary.400')}
                                variant="caption-bold"
                                pl={isSimpleMode ? '0px' : '8px'}
                            >
                                1 {limitOrderForm.tokenX.symbol} =
                            </Text>
                        </HStack>
                        <Stack
                            pl="0"
                            h={{ base: '55px', sm: '50px', xxl: '55px' }}
                            w="100%"
                            justifyContent="space-between"
                            alignItems={isSimpleMode ? 'center' : 'start'}
                            direction={isSimpleMode ? 'row' : 'column'}
                        >
                            <HStack>
                                <CustomNumberInput
                                    w="100%"
                                    onBlur={(value: any) => {
                                        // TODO fee
                                        isNumeric(value) &&
                                            dispatch.tradeLimitOrder.setSellPrice({
                                                sellPriceDecimal: value,
                                                fee: limitOrderForm.fee,
                                            });
                                    }}
                                    errorInfo={getFieldValidateInfo(validateResult, 'sellPrice')}
                                    inputValue={
                                        limitOrderForm.sellPriceDecimal
                                            ? limitOrderForm.sellPriceDecimal > 1
                                                ? new BigNumber(limitOrderForm.sellPriceDecimal).toFixed(5)
                                                : limitOrderForm.sellPriceDecimal > 0.0001
                                                ? new BigNumber(limitOrderForm.sellPriceDecimal).toFixed(10)
                                                : new BigNumber(limitOrderForm.sellPriceDecimal).toFixed(13)
                                            : 0
                                    }
                                    setIsInputFocus={setIsInputToFocus}
                                />
                                <Text
                                    ml="0px !important"
                                    variant="caption"
                                    className={i_text_copy_bold}
                                    fontSize="12px"
                                    color={colorTheme('tertiary.800', 'tertiary.200')}
                                >
                                    {limitOrderForm.tokenY.symbol}
                                </Text>
                            </HStack>
                        </Stack>
                        <HStack
                            spacing="5px"
                            w="100%"
                            h="22px"
                            mb="12px"
                            pb={isSimpleMode ? '0px' : '22px'}
                            justifyContent={isSimpleMode ? 'unset' : 'end'}
                            mt={isSimpleMode ? { base: '0px !important', sm: '-10px !important', xxl: '0px !important' } : '0px !important'}
                        >
                            <Text
                                w="47%"
                                variant="caption"
                                color={colorTheme('tertiary.600', 'tertiary.400')}
                                pl={isSimpleMode ? '0px' : '8px'}
                                textAlign="center"
                            >
                                ~{' '}
                                {formatNumber(
                                    (limitOrderForm.sellPriceDecimal ?? 0) * (limitOrderForm.amountDecimal ?? 0) * tokenYEntity.tokenPrice()
                                )}{' '}
                                USD
                            </Text>
                            <HStack ml="auto !important">
                                {marketPrice.map((item, index) => (
                                    <CustomButton
                                        onClick={() => {
                                            const rate = item === t('Market Price') ? 1 : Number(item) / 100 + 1;
                                            dispatch.tradeLimitOrder.setSellPrice({
                                                sellPriceDecimal: Number(rate * poolPriceDecimal),
                                                fee: limitOrderForm.fee,
                                            });
                                        }}
                                        py="auto"
                                        px="10px"
                                        key={index}
                                        h="22px"
                                        borderRadius="3px"
                                        alignItems="center"
                                        justifyContent="center"
                                        variant={item === t('Market Price') ? 'tertiary' : 'primary2'}
                                        text={typeof item === 'number' ? `+${item}%` : item}
                                        fontClass={i_text_d}
                                        fontSize="12px !important"
                                    />
                                ))}
                            </HStack>
                        </HStack>
                    </VStack>
                </Card>
                {priceToolTip}
            </VStack>
        </VStack>
    );

    return type === 'mobile' ? (
        <>
            <VStack w="100%" pb="34px">
                <Stack direction="column" w="100%" spacing="9px">
                    <Card variant="tabTopCard" w="100%" pb="14px" px="19px" boxShadow="0px 0px 26px rgba(166, 135, 198, 0.25)">
                        <HStack w="100%" justifyContent="center" pt="18px">
                            <Text
                                w="100%"
                                h="34px"
                                textAlign="center"
                                variant="caption"
                                fontSize="15px"
                                fontFamily="Montserrat-Bold"
                                color="#529B96"
                                lineHeight="21px"
                                ml="30px"
                            >
                                {t('Limit Order')}
                            </Text>

                            <Image
                                boxSize="20px"
                                src="/assets/limitOrder/myOrder.svg"
                                fallbackSrc="/assets/limitOrder/myOrder.svg"
                                mb="10px !important"
                                onClick={() => {
                                    history.push('/limit/my-orders');
                                }}
                            ></Image>
                        </HStack>
                        <Divider border="1px solid #EBE6F0;"></Divider>

                        <Stack direction="row" spacing="10px" pt="13px" mb="8px">
                            <TokenSelectButton
                                type="mobile"
                                size={'xs'}
                                token={limitOrderForm.tokenX}
                                handleSelect={(tokenInfo) =>
                                    dispatch.tradeLimitOrder.setLimitOrderFormToken({
                                        isUpper: false,
                                        chainId,
                                        tokenInfo,
                                    })
                                }
                            />
                            <IconTool token={limitOrderForm.tokenX}></IconTool>
                            <HStack ml="auto !important">
                                <Text variant="caption" color="#529B96" whiteSpace="nowrap">
                                    {t('Balance')} :<Text variant="caption">{formatNumber(tokenXEntity.tokenBalance())}</Text>
                                </Text>
                            </HStack>
                        </Stack>
                        {AmountInputX}

                        <Stack w="100%" alignItems="center" my="10px">
                            <Flex
                                boxSize="38px"
                                bg={colorTheme('#F8F8FA', '#3A2F53')}
                                borderRadius="30px"
                                boxShadow={colorTheme('0px 0px 9px rgba(119, 84, 147, 0.25)', '0px 0px 9px rgba(40, 9, 64, 0.25)')}
                                alignItems="center"
                                justifyContent="center"
                                onClick={dispatch.tradeLimitOrder.toggleTokenOrder}
                            >
                                <Image
                                    boxSize="16px"
                                    src={colorTheme('/assets/limitOrder/Change.svg', '/assets/limitOrder/Change.svg')}
                                    fallbackSrc={colorTheme('/assets/limitOrder/Change.svg', '/assets/limitOrder/Change.svg')}
                                    onClick={dispatch.tradeLimitOrder.toggleTokenOrder}
                                    cursor="pointer"
                                ></Image>
                            </Flex>
                        </Stack>

                        <Stack w="100%" direction="row" spacing="10px" alignItems="center">
                            <TokenSelectButton
                                type="mobile"
                                size="xs"
                                token={limitOrderForm.tokenY}
                                handleSelect={(tokenInfo) =>
                                    dispatch.tradeLimitOrder.setLimitOrderFormToken({
                                        isUpper: true,
                                        chainId,
                                        tokenInfo,
                                    })
                                }
                            />

                            <IconTool token={limitOrderForm.tokenY} />
                            <HStack ml="auto !important">
                                <Text variant="caption" color="#529B96" whiteSpace="nowrap">
                                    {t('Balance')} :<Text variant="caption">{formatNumber(tokenYEntity.tokenBalance())}</Text>
                                </Text>
                            </HStack>
                        </Stack>

                        <VStack spacing="10px" alignItems="flex-start" pt="9px">
                            <Card
                                variant="lightGary"
                                w="100%"
                                h="57px"
                                pr="10px"
                                pl="18px"
                                pt="4px"
                                pb="7px"
                                border={isInputToFocus ? '2px solid #529B96' : colorTheme('2px solid #ffffff', '2px solid #211834')}
                            >
                                <AmountInput
                                    type="mobile"
                                    handleSetValue={() => {}}
                                    disabled={true}
                                    token={limitOrderForm.tokenY}
                                    price={tokenYEntity.tokenPrice()}
                                    balance={tokenYEntity.tokenBalance()}
                                    inputValue={(limitOrderForm.sellPriceDecimal ?? 0) * (limitOrderForm.amountDecimal ?? 0)}
                                    w="100%"
                                    fontClass={i_h2}
                                    notShowShortcut={true}
                                />
                            </Card>
                        </VStack>

                        <VStack spacing="10px" bottom="0" position="static" ml="0px !important">
                            <Card variant="none" w="100%" px="0px" pb="12px">
                                <HStack py="8px">
                                    <Text
                                        variant="caption"
                                        className={i_text_copy}
                                        fontFamily="Montserrat"
                                        fontWeight="bold"
                                        color={colorTheme('#8D909E', 'tertiary.200')}
                                    >
                                        {t('Fees Tiers')}
                                    </Text>
                                </HStack>
                                <FeesTiersSelect
                                    type="mobile"
                                    handleSelect={(fee: FeeTier) => dispatch.tradeLimitOrder.setLimitOrderFormFee(fee)}
                                    currentFee={limitOrderForm.fee}
                                />
                            </Card>
                        </VStack>
                        {tradeLimitOrder.poolStatusLoading ? <Loading /> : poolPrice === 0 ? poolNotExisted : priceInput}
                    </Card>
                </Stack>
                {isShowError && <ErrorInfo content={getFieldValidateInfo(validateResult, 'amount')} mt="13px !important"></ErrorInfo>}
                <WarningInfo isShow={showWarning} onclose={setShowWarning} mt="13px !important"></WarningInfo>

                {priceToolTip}
                <Stack w="100%" direction="column" mt={'11px !important'}>
                    <CustomButton
                        hidden={!tokenXApproved}
                        disabled={validateResult !== undefined || LIMIT_ORDER_DISABLE_CHAIN.includes(chainId)}
                        variant="lightGreen"
                        text={t('Add')}
                        mr="30px"
                        w="100%"
                        h="50px"
                        fontSize="14px"
                        _disabled={{
                            opacity: '0.3',
                        }}
                        onClick={() => {
                            setShowLoadingModal(true);
                            setInitTradeState();
                            dispatch.tradeLimitOrder
                                .addLimitOrder({
                                    account,
                                    limitOrderForm,
                                    chainId,
                                    limitOrderMgrContract,
                                    gasPrice,
                                    onGoingCallback: (toastLink: ToastLink) => {
                                        setTradeStateIfNotSubmitted();
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                } as AddLimitOrderParams)
                                .then((e: any) => {
                                    tokenXEntity.handleApproveSuccess();
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    setTradeStateIfNotSuccess();
                                    setToastLink(toastLink);
                                    toast('success', 'Successfully', undefined, toastLink);
                                })
                                .catch((e) => {
                                    setShowLoadingModal(false);
                                    toast('info', typeof e === 'string' ? e : e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={tokenXApproved || !account || !limitOrderForm.tokenX.symbol}
                        variant="lightGreen"
                        text={t('Approve') + ' ' + limitOrderForm.tokenX.symbol}
                        mr="30px"
                        w="100%"
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            setInitTradeState();
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    setTradeStateIfNotSubmitted();
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    setTradeStateIfNotSuccess();
                                    setToastLink(toastLink);
                                    tokenXEntity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                </Stack>
                <Box h="30px" />
                <TradeLoadingModal
                    isOpen={showLoadingModal}
                    onClose={() => {
                        setShowLoadingModal(false);
                        setInitTradeState();
                    }}
                    content={
                        'Add Order ' +
                        (limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5)) +
                        ' ' +
                        limitOrderForm.tokenX.symbol
                    }
                    tokenX={limitOrderForm.tokenX}
                    tokenXAmount={String(limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5))}
                    toastLink={toastLink}
                    tradeState={tradeState}
                ></TradeLoadingModal>
            </VStack>
        </>
    ) : (
        <>
            <VStack
                w={isSimpleMode ? { base: '840px', sm: '587px', xxl: '881px' } : '370px'}
                h={isSimpleMode ? 'auto' : poolPrice === 0 ? '566px' : 'unset'}
                px={isSimpleMode ? { base: '25px', xxl: '44px' } : ''}
                pt={isSimpleMode ? '0px' : '0px'}
                pb="34px"
                spacing="0"
            >
                {isSimpleMode && isXXl && (
                    <WarningInfo
                        isShow={showWarning}
                        onclose={setShowWarning}
                        mb={isSimpleMode ? '13px' : '0px'}
                        mt={isSimpleMode ? '0px' : '8px'}
                    ></WarningInfo>
                )}
                <Stack
                    direction={isSimpleMode ? 'row' : 'column'}
                    w="100%"
                    h={isSimpleMode ? { base: '400px', sm: '331px', xxl: '400px' } : 'unset'}
                    spacing={isSimpleMode ? '19px' : '10px'}
                >
                    <VStack>
                        <Card
                            variant={isSimpleMode ? 'deep' : 'tabTopCard'}
                            w={isSimpleMode ? { base: '387px', sm: '224px', xxl: '387px' } : '100%'}
                            h={isSimpleMode ? { base: '400px', sm: '331px', xxl: '400px' } : 'unset'}
                            position="relative"
                            pt="18px"
                            pb={isSimpleMode ? { base: '26px', sm: '16px', xxl: '26px' } : '0px'}
                            px={isSimpleMode ? 'auto' : '20px'}
                        >
                            <Stack
                                direction={isSimpleMode ? 'row' : 'column'}
                                justifyContent={isSimpleMode ? 'center' : 'space-between'}
                                w="100%"
                            >
                                <VStack h={isSimpleMode ? '312px' : 'unset'}>
                                    <Stack
                                        w="100%"
                                        direction={isSimpleMode ? 'column' : 'row'}
                                        spacing="10px"
                                        alignItems={isSimpleMode ? 'flex-start' : 'center'}
                                    >
                                        <HStack w={{ base: '40px', sm: '50px' }}>
                                            <Text
                                                variant="caption"
                                                w={{ base: '40px', sm: '60px' }}
                                                fontWeight="bold"
                                                color={colorTheme('tertiary.600', 'tertiary.200')}
                                            >
                                                {t('Sell')}
                                            </Text>
                                        </HStack>
                                        <TokenSelectButton
                                            w={isSimpleMode ? { base: '286px', sm: '173px', xxl: '286px' } : '152px'}
                                            ml="auto !important"
                                            size={isSimpleMode ? 'lg' : 'xs'}
                                            token={limitOrderForm.tokenX}
                                            handleSelect={(tokenInfo) =>
                                                dispatch.tradeLimitOrder.setLimitOrderFormToken({
                                                    isUpper: false,
                                                    chainId,
                                                    tokenInfo,
                                                })
                                            }
                                            pl={isSimpleMode ? { base: '44px', sm: '22px', xxl: '44px' } : '15px'}
                                        />
                                        {!isSimpleMode && <IconTool token={limitOrderForm.tokenX}></IconTool>}
                                    </Stack>

                                    <Stack
                                        w="100%"
                                        direction={isSimpleMode ? 'column' : 'row'}
                                        spacing="10px"
                                        mt={
                                            isSimpleMode
                                                ? { base: '40px !important', sm: '33px !important', xxl: '40px !important' }
                                                : '20px !important'
                                        }
                                        alignItems={isSimpleMode ? 'flex-start' : 'center'}
                                    >
                                        <HStack w={{ base: '40px', sm: '60px' }}>
                                            <Text
                                                variant="caption"
                                                fontWeight="bold"
                                                mr={{ base: '20px', sm: '36px' }}
                                                color={colorTheme('tertiary.600', 'tertiary.200')}
                                            >
                                                {t('Buy')}
                                            </Text>
                                        </HStack>

                                        <TokenSelectButton
                                            w={isSimpleMode ? { base: '286px', sm: '173px', xxl: '286px' } : '152px'}
                                            ml="auto !important"
                                            size={isSimpleMode ? 'lg' : 'xs'}
                                            token={limitOrderForm.tokenY}
                                            handleSelect={(tokenInfo) =>
                                                dispatch.tradeLimitOrder.setLimitOrderFormToken({
                                                    isUpper: true,
                                                    chainId,
                                                    tokenInfo,
                                                })
                                            }
                                            pl={isSimpleMode ? { base: '44px', sm: '22px', xxl: '44px' } : '15px'}
                                        />
                                        {!isSimpleMode && <IconTool token={limitOrderForm.tokenY}></IconTool>}
                                    </Stack>
                                </VStack>
                                <Box
                                    display={isSimpleMode ? 'block' : 'none'}
                                    position="absolute"
                                    borderRadius="50%"
                                    border={colorTheme('6px solid #ebebf7', 'undefined')}
                                    w="44px"
                                    h="44px"
                                    background={colorTheme('#FFFFFF', '#1A282C')}
                                    top={
                                        showWarning
                                            ? { base: '158px', sm: '132px', xxl: '158px' }
                                            : { base: '158px', sm: '132px', xxl: '158px' }
                                    }
                                    left="48%"
                                    transform="translateX(-50%) translateY(-50%)"
                                    backgroundImage={colorTheme('/assets/swap/transicon.svg', '/assets/swap/transicon_dark.svg')}
                                    backgroundSize="18px 24px"
                                    backgroundRepeat="no-repeat"
                                    backgroundPosition="center"
                                    cursor="pointer"
                                    onClick={dispatch.tradeLimitOrder.toggleTokenOrder}
                                    boxShadow={colorTheme('0px 0px 4px rgba(191, 179, 200, 0.25)', '0px 0px 9px 5px #120C1F')}
                                />

                                <Divider w="100%" display={isSimpleMode ? 'none' : 'block'} pt="5px"></Divider>
                                <VStack spacing="10px" bottom="0" position={isSimpleMode ? 'absolute' : 'static'} ml="0px !important">
                                    <Card
                                        variant={isSimpleMode ? 'deep' : 'none'}
                                        h={isSimpleMode ? { base: '108px', sm: '94px', xxl: '108px' } : 'unset'}
                                        w={isSimpleMode ? { base: '387px', sm: '224px', xxl: '387px' } : '100%'}
                                        pr={isSimpleMode ? { base: '23px', sm: '10px', xxl: '23px' } : '0px'}
                                        pl={isSimpleMode ? { base: '40px', sm: '10px', xxl: '40px' } : '0px'}
                                        pb={{ base: '15px' }}
                                        justifyContent="center"
                                    >
                                        <HStack pt={isSimpleMode ? '10px' : '8px'} pb={isSimpleMode ? '10px' : '8px'}>
                                            <Text variant="caption" fontWeight="bold" color={colorTheme('tertiary.600', 'tertiary.200')}>
                                                {t('Fees Tiers')}
                                            </Text>
                                        </HStack>
                                        <FeesTiersSelect
                                            handleSelect={(fee: FeeTier) => dispatch.tradeLimitOrder.setLimitOrderFormFee(fee)}
                                            currentFee={limitOrderForm.fee}
                                            isAdvanceMode={!isSimpleMode}
                                            fontSize={{ base: '13px !important', sm: '12px !important', xxl: '13px !important' }}
                                            textProps={{ p: '7px' }}
                                        />
                                    </Card>
                                </VStack>
                            </Stack>
                        </Card>
                    </VStack>

                    <Card
                        variant="deep"
                        w={isSimpleMode ? '388px' : 'unset'}
                        h={isSimpleMode ? { base: '400px', sm: '331px', xxl: '400px' } : 'unset'}
                        pt="18px"
                        pb={{ base: '26px', sm: '16px', xxl: '26px' }}
                        px="auto"
                    >
                        {tradeLimitOrder.poolStatusLoading ? (
                            <Loading mt={isSimpleMode ? '100px !important' : 'unset'} />
                        ) : poolPrice === 0 ? (
                            poolNotExisted
                        ) : (
                            limitOrderInput
                        )}
                    </Card>
                </Stack>

                <CurrentPriceCard
                    hidden={!limitOrderForm.tokenX.symbol || !limitOrderForm.tokenY.symbol || !isSimpleMode}
                    tokenA={limitOrderForm.tokenX}
                    tokenB={limitOrderForm.tokenY}
                    price={poolPriceDecimal}
                    handleToggle={() => dispatch.tradeLimitOrder.toggleTokenOrder()}
                    onlyTogglePrice={true}
                    mt="12px !important"
                    pl={{ base: '65px', sm: '43px', xxl: '65px' }}
                    pr={isSimpleMode ? { base: '30px', xxl: '30px' } : { base: '75px', sm: '22px', xxl: '75px' }}
                />
                {isShowError && <ErrorInfo content={getFieldValidateInfo(validateResult, 'amount')} mt="13px !important"></ErrorInfo>}

                <Stack
                    w={isSimpleMode ? 'unset' : '100%'}
                    direction={isSimpleMode ? 'row' : 'column'}
                    mt={isSimpleMode ? '24px !important' : '14px !important'}
                >
                    <CustomButton
                        w={isSimpleMode ? '204px' : '100%'}
                        hidden={!tradeMode.isSimpleMode}
                        h="50px"
                        text={t('My Orders')}
                        variant="outline"
                        mr="30px !important"
                        fontSize="14px !important"
                        onClick={() => {
                            history.push('/limit/my-orders');
                        }}
                        color={colorTheme('#0166FF', 'tertiary.200')}
                    />
                    <CustomButton
                        hidden={!tokenXApproved}
                        disabled={validateResult !== undefined || LIMIT_ORDER_DISABLE_CHAIN.includes(chainId)}
                        variant="lightGreen"
                        text={t('Add')}
                        mr="30px"
                        w={isSimpleMode ? '204px' : '100%'}
                        h="50px"
                        fontSize="14px !important"
                        onClick={() => {
                            setInitTradeState();
                            setShowLoadingModal(true);
                            dispatch.tradeLimitOrder
                                .addLimitOrder({
                                    account,
                                    limitOrderForm,
                                    chainId,
                                    limitOrderMgrContract,
                                    gasPrice,
                                    onGoingCallback: (toastLink: ToastLink) => {
                                        setTradeStateIfNotSubmitted();
                                        setToastLink(toastLink);
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                } as AddLimitOrderParams)
                                .then((e: any) => {
                                    setTradeStateIfNotSuccess();
                                    tokenXEntity.handleApproveSuccess();
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    setToastLink(toastLink);
                                    toast('success', 'Successfully', undefined, toastLink);
                                })
                                .catch((e) => {
                                    setShowLoadingModal(false);
                                    toast('info', typeof e === 'string' ? e : e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={tokenXApproved || !account || !limitOrderForm.tokenX.symbol}
                        variant="lightGreen"
                        text={t('Approve') + ' ' + limitOrderForm.tokenX.symbol}
                        mr="30px"
                        w={isSimpleMode ? '204px' : '100%'}
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            setInitTradeState();
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    setTradeStateIfNotSubmitted();
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    setTradeStateIfNotSuccess();
                                    tokenXEntity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                </Stack>
                <Box h="30px" hidden={isSimpleMode}></Box>
            </VStack>
            <TradeLoadingModal
                isOpen={showLoadingModal}
                onClose={() => {
                    setShowLoadingModal(false);
                    setInitTradeState();
                }}
                content={
                    'Add Order ' +
                    (limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5)) +
                    ' ' +
                    limitOrderForm.tokenX.symbol
                }
                tokenX={limitOrderForm.tokenX}
                tokenXAmount={String(limitOrderForm.amountDecimal && Number(limitOrderForm.amountDecimal).toFixed(5))}
                toastLink={toastLink}
                tradeState={tradeState}
            ></TradeLoadingModal>
        </>
    );
};

export default LimitForm;
