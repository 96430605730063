import BigNumber from 'bignumber.js';
import { createModel } from '@rematch/core';
import { isAddress } from 'ethers/lib/utils';
import { Contract } from 'web3-eth-contract';

import {
    decodeMethodResult,
    getiZiSwapPoolContract,
    getMiningFixRangeiZiContract,
    getMiningFixRangeiZiTimestampContract,
    getMulticallContract,
    getVeiZiContract,
} from '../../../../../utils/contractHelpers';
import { getLiquidityValue } from '../liquidity';
import { RootModel } from '../../../index';
import produce from 'immer';
import { tokenAddr2Token, tokenSymbol2token } from '../../../../../config/tokens';
import { ChainId, TokenSymbol, FarmFixRangeiZiContractVersion } from '../../../../../types/mod';
import { parallelCollect} from '../../../../../net/contractCall/parallel';
import { getPositionPoolKey } from '../../../common/positionPoolHelper';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { getChain } from '../../../../../config/chains';
import { VEIZI_ADDRESS } from '../../../../../config/veizi/veiziContracts';
import { toFeeNumber } from '../../../../../utils/funcs';
import { FarmControl, FarmState, InitPoolListDataParams, InitPoolListMetaParams, InitLiquidityParams, LiquidityEntry, MiningPoolData, MiningPoolMeta, MiningPoolUserData, PoolEntryState, RefreshPoolListDataAndPositionParams, MiningContractInfo } from './types';
import { findPoolEntryByPoolKey, getPoolAPR, getPoolAPRTimestamp, getPoolLiquidity, getPriceRangeDecimal, getPriceRangeUndecimal, getVLiquidity } from './funcs';
import { GetMiningContractInfoResponse } from '../../../../../types/abis/farm/iZiSwap/fixRange/FixRange';
import { StateResponse } from '../../../../../types/abis/iZiSwap/Pool';
import { point2PriceDecimal, point2PriceUndecimal } from '../price';
import { LiquiditiesResponse, PoolMetasResponse } from '../../../../../types/abis/iZiSwap/LiquidityManager';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../../config/trade/tradeContracts';
import { MULTICALL_ADDRESS } from '../../../../../config/multicall/multicallContracts';


export const farmFixRangeiZi = createModel<RootModel>()({
    state: {
        liquidityManagerContract: undefined as unknown as Contract,
        poolEntryList: [],
        farmView: {
            isFarmDataLoading: false,
            isUserDataLoading: false,
        },
        farmControl: {
            sortBy: undefined,
            stakedOnly: false,
            searchKey: undefined,
            type: 'live',
        }
    } as FarmState,
    reducers: {
        setFarmState: (state: FarmState, payload: FarmState) => {
            return { ...state, ...payload };
        },
        setFarmControl: (state: FarmState, farmControl: FarmControl) => produce(state, draft => {
            draft.farmControl = { ...farmControl };
        }),
        setPoolEntryList: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            // void freeze object when initPoolListByMeta set meta state first
            draft.poolEntryList = JSON.parse(JSON.stringify(payload));
        }),
        setPoolEntryListMeta: (state: FarmState, { chainId, poolEntryList }: { chainId: ChainId, poolEntryList: PoolEntryState[] }) => {
            return { ...state, poolEntryList, currentFarmChainId: chainId };
        },
        setPoolEntryListData: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, poolEntry.meta.liquidityPoolKey);
                draftPoolEntry.data = poolEntry.data;
            }
        }),
        setPositionData: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            const positionPoolKeySet = new Set();
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, poolEntry.meta.liquidityPoolKey);
                draftPoolEntry.userData = poolEntry.userData;
                draftPoolEntry.liquidityList = poolEntry.liquidityList;
                draftPoolEntry.stakedLiquidityList = poolEntry.stakedLiquidityList;

                positionPoolKeySet.add(poolEntry.meta.liquidityPoolKey);
            }
            // clean other
            draft.poolEntryList.filter(p => !positionPoolKeySet.has(p.meta.liquidityPoolKey)).forEach(p => {
                p.userData.earned = ['0', '0'];
                p.liquidityList = [];
                p.stakedLiquidityList = [];
            });
        }),
        setFarmDataLoading: (state: FarmState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isFarmDataLoading = isLoading;
        }),
        setUserDataLoading: (state: FarmState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isUserDataLoading = isLoading;
        }),
        togglePoolMetaInitialToggle: (state: FarmState, positionPoolKey: string) => produce(state, draft => {
            const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, positionPoolKey);
            draftPoolEntry.meta.initialToggle = !draftPoolEntry.meta.initialToggle;
        }),
    },
    effects: (dispatch) => ({
        async initPoolListMeta(initPoolListMetaParams: InitPoolListMetaParams): Promise<void> {
            const { chainId, metaList } = initPoolListMetaParams;
            if (!chainId) { return; }
            const startTime = new Date();

            const poolEntryList = [] as PoolEntryState[];
            // TODO filter
            // TODO contract multicall or web3 batch request or parallel
            for (const configMeta of metaList ?? []) {
                const userData = { earned: ['0', '0'] } as MiningPoolUserData;
                const data = {} as MiningPoolData;
                const contractVersion = configMeta.contractVersion? configMeta.contractVersion : FarmFixRangeiZiContractVersion.V1;
                const additionalKey = configMeta.additionalKey ?? '01';
                const liquidityPoolKey = getPositionPoolKey(configMeta.tokenA.address, configMeta.tokenB.address, configMeta.feeTier, contractVersion, additionalKey);
                const useTimestamp = configMeta.useTimestamp ?? false
                if (!configMeta.useOriginLiquidity) {
                    configMeta.useOriginLiquidity = false;
                }
                const meta = { ...configMeta,  liquidityPoolKey, contractVersion, useTimestamp} as MiningPoolMeta;

                const poolEntryState = { meta, data, userData } as PoolEntryState;
                poolEntryList.push(poolEntryState);
            }
            dispatch.farmFixRangeiZi.setPoolEntryListMeta({ chainId, poolEntryList });
            // sync init meta data for render basic view
            console.log(`initPoolListMeta end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async initPoolListData(initPoolListDataParams: InitPoolListDataParams, rootState): Promise<void> {
            const { chainId, web3, liquidityManagerContract } = initPoolListDataParams;
            if (!chainId || !web3 || !liquidityManagerContract) { return; }
            if (rootState.farmFixRangeiZi.poolEntryList.length === 0) { return; }

            const blockDeltaU = getChain(chainId)?.blockDeltaU ?? 10;

            const poolEntryDataList = [] as PoolEntryState[];
            const asyncProcessList: Promise<void>[] = [];


            const metaInfoMulticall = [] as string[]
            const metaInfoAddress = [] as string[]

            const miningContractList = [] as Contract[]
            const poolContractList = [] as Contract[]

            for (const poolEntry of rootState.farmFixRangeiZi.poolEntryList) {
                const {meta} = poolEntry
                const miningContract = meta.useTimestamp ? getMiningFixRangeiZiTimestampContract(meta.miningContract, web3, meta.contractVersion) : getMiningFixRangeiZiContract(meta.miningContract, web3, meta.contractVersion)
                miningContractList.push(miningContract)
                metaInfoMulticall.push(miningContract.methods.getMiningContractInfo().encodeABI())
                metaInfoAddress.push(meta.miningContract)
                const poolContract = getiZiSwapPoolContract(meta.swapPoolAddress, web3)
                poolContractList.push(poolContract as unknown as Contract)
                metaInfoMulticall.push(poolContract?.methods.state().encodeABI())
                metaInfoAddress.push(meta.swapPoolAddress)
            }
            const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3)
            const poolInfoMulticallResult = await multicallContract.methods.multicall(metaInfoAddress, metaInfoMulticall).call()
            const metaInfo = [] as MiningContractInfo[]
            const poolStateInfo = [] as StateResponse[]
            for (let idx = 0; idx < rootState.farmFixRangeiZi.poolEntryList.length; idx ++) {
                // console.log('poolInfoMulticallResult: ')
                const metaInfoResponse = decodeMethodResult(miningContractList[idx], 'getMiningContractInfo', poolInfoMulticallResult.results[idx * 2]) as MiningContractInfo
                metaInfo.push(metaInfoResponse)
                const state = decodeMethodResult(poolContractList[idx], 'state', poolInfoMulticallResult.results[idx * 2 + 1]) as StateResponse
                poolStateInfo.push(state)
            }
            // for (let idx = 0; idx < min in)
            console.log('poolInfoMulticallResult: ', poolInfoMulticallResult)
            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmFixRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntry = rootState.farmFixRangeiZi.poolEntryList[poolEntryIdx]
                const { meta } = poolEntry;
                const data = {} as MiningPoolData;
                const poolEntryData = {
                    meta: { liquidityPoolKey: meta.liquidityPoolKey } as MiningPoolMeta,
                    data
                } as PoolEntryState;

                poolEntryDataList.push(poolEntryData);

                // const miningContract = getMiningFixRangeContract(meta.miningContract, web3);
                // const miningContract = getMiningFixRangeiZiContract(meta.miningContract, web3, meta.contractVersion);

                const miningContract = miningContractList[poolEntryIdx]

                const asyncProcess = async () => {
                    // 1. get mining contract info

                    // const getMiningContractInfoMulticall = miningContract.methods.getMiningContractInfo().encodeABI()
                    // const res = await miningContract.methods.multicall(getMiningContractInfoMulticall).call()

                    // console.log(res)
                    // const miningKeyInfo = decodeMethodResult(miningContract, 'getMiningContractInfo', res) as GetMiningContractInfoResponse
                    // console.log('miningKeyInfo: ', miningKeyInfo)

                    const miningKeyInfo = metaInfo[poolEntryIdx]
                    const state = poolStateInfo[poolEntryIdx]

                    // 3. get swap and reward token price and set price data
                    const [tokenPriceA, tokenPriceB, tokenPriceiZi] = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB),
                        dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId))
                    );

                    const tokenX = meta.tokenA.address.toLowerCase() < meta.tokenB.address.toLowerCase() ? meta.tokenA : meta.tokenB
                    const tokenY = meta.tokenA.address.toLowerCase() < meta.tokenB.address.toLowerCase() ? meta.tokenB : meta.tokenA

                    const tokenXPriceDecimal = tokenX.address === meta.tokenA.address ? tokenPriceA : tokenPriceB
                    const tokenYPriceDecimal = tokenY.address === meta.tokenA.address ? tokenPriceA : tokenPriceB

                    if (meta.useTimestamp) {
                        const currentTime = new Date().getTime() / 1000
                        data.isEnded = Number(miningKeyInfo.endTime_) < currentTime
                        data.endTime = Number(miningKeyInfo.endTime_)
                        data.secondsLeft = data.isEnded? 0: data.endTime - currentTime
                    } else {
                        data.isEnded = Number(miningKeyInfo.endBlock_) < Number(rootState.block.currentBlock)
                        data.endBlock = Number(miningKeyInfo.endBlock_)
                        data.secondsLeft = data.isEnded? 0: (Number(miningKeyInfo.endBlock_) - Number(rootState.block.currentBlock)) * blockDeltaU
                    }


                    const vlScale = meta.useOriginLiquidity ? 1 : 1e6;

                    data.capital = getPoolLiquidity(
                        miningKeyInfo, 
                        Number(state.currentPoint), 
                        state.liquidity,
                        state.liquidityX,
                        tokenX,
                        tokenY,
                        tokenXPriceDecimal,
                        tokenYPriceDecimal,
                        vlScale,
                    )
                    data.totalVLiquidity = miningKeyInfo.totalVLiquidity_

                    if (meta.contractVersion === FarmFixRangeiZiContractVersion.V1) {
                        data.totalNiZi = await miningContract?.methods.totalNIZI().call();
                        data.totalValidVeiZi = '0';
                    } else {
                        // todo: fill totalValidVeiZi
                        // data.totalValidVeiZi = parseFloat(miningKeyInfo.totalValidVeiZi ?? '0');
                        data.totalNiZi = '0';
                    }
                    data.tvl = data.capital + (amount2Decimal(new BigNumber(data.totalNiZi), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0) * tokenPriceiZi;

                    data.currentPoint = Number(state.currentPoint);
                    data.currentLiquidity = state.liquidity;
                    data.currentLiquidityX = state.liquidityX;

                    let apr = 0;
                    let totalRewardWorthPeryear = 0;
                    data.reward = [];

                    for (const rewardInfo of miningKeyInfo.rewardInfos_) {
                        const rewardTokenAddress = rewardInfo.rewardToken
                        const rewardToken = tokenAddr2Token(rewardTokenAddress, chainId)
                        const rewardTokenPrice = await dispatch.token.fetchTokenPriceIfMissing(rewardToken) ?? 0
                        const {apr: rewardAPR, rewardsPerYear} = meta.useTimestamp ? getPoolAPRTimestamp(rewardInfo.rewardPerSecond as string, data.capital, {token: rewardToken, price: rewardTokenPrice}) : getPoolAPR(rewardInfo.rewardPerBlock as string, chainId, data.capital, {token: rewardToken, price: rewardTokenPrice})
                        apr += rewardAPR
                        totalRewardWorthPeryear += rewardsPerYear
                        if (!meta.useTimestamp) {
                            data.reward.push({
                                token: rewardToken, 
                                priceDecimal: rewardTokenPrice, 
                                rewardUndecimalPerBlock: rewardInfo.rewardPerBlock as string,
                                rewardDecimalPerBlock: amount2Decimal(new BigNumber(rewardInfo.rewardPerBlock as string), rewardToken) ?? 0
                            })
                        } else {
                            data.reward.push({
                                token: rewardToken, 
                                priceDecimal: rewardTokenPrice, 
                                rewardUndecimalPerSecond: rewardInfo.rewardPerSecond as string,
                                rewardDecimalPerSecond: amount2Decimal(new BigNumber(rewardInfo.rewardPerSecond as string), rewardToken) ?? 0
                            })
                        }
                    }

                    data.totalRewardWorthPerYear = totalRewardWorthPeryear;

                    if (meta.iZiBoost || meta.veiZiBoost) {
                        data.apr=[apr/2.5, apr];
                    } else {
                        data.apr = [apr];
                    }

                    data.rewardLowerTick = Number(miningKeyInfo.rewardLowerTick_);
                    data.rewardUpperTick = Number(miningKeyInfo.rewardUpperTick_);

                    const {priceLowerDecimalAByB, priceUpperDecimalAByB} = getPriceRangeDecimal(meta.tokenA, meta.tokenB, data.rewardLowerTick, data.rewardUpperTick)

                    data.rewardMinPriceDecimalAByB = priceLowerDecimalAByB
                    data.rewardMaxPriceDecimalAByB = priceUpperDecimalAByB

                    const {priceLowerUndecimalAByB, priceUpperUndecimalAByB} = getPriceRangeUndecimal(meta.tokenA, meta.tokenB, data.rewardLowerTick, data.rewardUpperTick)
                    data.rewardMinPriceUndecimalAByB = priceLowerUndecimalAByB
                    data.rewardMaxPriceUndecimalAByB = priceUpperUndecimalAByB

                    // 4. set position data
                    data.poolContract = poolContractList[poolEntryIdx];
                    data.sqrtPriceX96 = state.sqrtPrice_96;

                    data.priceUndecimalAByB = point2PriceUndecimal(meta.tokenA, meta.tokenB, Number(state.currentPoint))
                    data.priceDecimalAByB = point2PriceDecimal(meta.tokenA, meta.tokenB, Number(state.currentPoint))

                    data.currentPoint = Number(state.currentPoint)

                };
                asyncProcessList.push(asyncProcess());
            }
            await Promise.all(asyncProcessList);
            dispatch.farmFixRangeiZi.setPoolEntryListData(poolEntryDataList);
        },
        async initPoolList(initPoolListParams: InitPoolListMetaParams & InitPoolListDataParams): Promise<void> {
            await dispatch.farmFixRangeiZi.initPoolListMeta(initPoolListParams);
            await dispatch.farmFixRangeiZi.initPoolListData(initPoolListParams);
        },
        async initLiquidity(initLiquidityParams: InitLiquidityParams, rootState): Promise<void> {
            const { chainId, web3, liquidityManagerContract, account } = initLiquidityParams;
            // TODO if account not set, clean?
            if (!chainId || !web3 || !account || !liquidityManagerContract || !rootState.farmFixRangeiZi.poolEntryList) { return; }
            if (rootState.farmFixRangeiZi.poolEntryList.length === 0) { return; }
            for (const poolEntry of rootState.farmFixRangeiZi.poolEntryList) {
                if (!poolEntry.data.reward) {
                    return;
                }
            }
            // TODO condition query in model?
            // TODO type hint for contract method
            // TODO opt: parallelCollect or better promi pipeline or multicall or web3 batch request
            // TODO store contract object ? use rematch select cache contract object
            const startTime = new Date();

            // // dispatch.farm.setUserDataLoading(true);

            const baseCalling = [] as string[]
            const baseCallingAddress = [] as string[]
            baseCalling.push(liquidityManagerContract.methods.balanceOf(account).encodeABI())
            baseCallingAddress.push(LIQUIDITY_MANAGER_ADDRESS[chainId])
            const allMiningContracts = [] as Contract[]
            for (const poolEntryState of rootState.farmFixRangeiZi.poolEntryList) {
                const miningContract = getMiningFixRangeiZiContract(poolEntryState.meta.miningContract, web3, poolEntryState.meta.contractVersion);
                allMiningContracts.push(miningContract)
                baseCalling.push(miningContract.methods.pendingRewards(account).encodeABI())
                baseCallingAddress.push(poolEntryState.meta.miningContract)
                baseCalling.push(miningContract.methods.getTokenIds(account).encodeABI())
                baseCallingAddress.push(poolEntryState.meta.miningContract)
                baseCalling.push(liquidityManagerContract.methods.isApprovedForAll(account, poolEntryState.meta.miningContract).encodeABI())
                baseCallingAddress.push(LIQUIDITY_MANAGER_ADDRESS[chainId])
            }

            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const veiZiContract = getVeiZiContract(veiZiAddress, web3);
            if (veiZiContract) {
                baseCallingAddress.push(veiZiAddress)
                baseCalling.push(veiZiContract.methods.stakingInfo(account).encodeABI())
            }
            const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3)
            const baseCallingResult = await multicallContract.methods.multicall(baseCallingAddress, baseCalling).call()
            const unstakedTokenTotal = Number(baseCallingResult.results[0])

            const isApprovedResult = [] as boolean[]
            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmFixRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const approveRes = baseCallingResult.results[1 + poolEntryIdx * 3 + 2]
                isApprovedResult.push(decodeMethodResult(liquidityManagerContract, 'isApprovedForAll', approveRes))
            }
            const stakingInfo = veiZiContract? decodeMethodResult(veiZiContract, 'stakingInfo', baseCallingResult.results[baseCalling.length - 1]) : undefined
            const veiZi = stakingInfo?.amount ?? '0';
            const veiZiNftId = stakingInfo?.nftId ?? '0';

            const veiZiDecimal = amount2Decimal(new BigNumber(stakingInfo?.amount?? 0), tokenSymbol2token(TokenSymbol.IZI,chainId)) ?? 0;


            const stakedTokenIdList: string[] = [];
            type miningPoolValueType = { tokenIdSet: Set<string>, address: string, poolEntry: PoolEntryState }
            const miningPoolData = {} as { [index: string]: miningPoolValueType };


            const stakedTokenId2PoolIdx = new Map<string, number>()
            const stakedTokenId2ListIdx = new Map<string, number>()
          
            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmFixRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntryState = rootState.farmFixRangeiZi.poolEntryList[poolEntryIdx]

                const poolEarnedList = decodeMethodResult(allMiningContracts[poolEntryIdx], 'pendingRewards', baseCallingResult.results[1 + poolEntryIdx * 3])
                const tokenIds = decodeMethodResult(allMiningContracts[poolEntryIdx], 'getTokenIds', baseCallingResult.results[2 + poolEntryIdx * 3])
                const userData = {} as MiningPoolUserData;
                userData.earned = poolEarnedList;
                userData.veiZi = veiZi;
                userData.veiZiNftId = veiZiNftId;
                userData.veiZiDecimal = veiZiDecimal;
                userData.vLiquidity = '0';
                userData.capital = 0;

                const liquidityPoolKey = poolEntryState.meta.liquidityPoolKey;
                const twoRewards = poolEntryState.meta.twoRewards;
                const contractVersion = poolEntryState.meta.contractVersion;

                const poolEntry = {
                    meta: { liquidityPoolKey, twoRewards, contractVersion, miningContract: poolEntryState.meta.miningContract } as MiningPoolMeta,
                    userData: userData,
                    liquidityList: [] as LiquidityEntry[],
                    stakedLiquidityList: [] as LiquidityEntry[],
                } as PoolEntryState;
                miningPoolData[poolEntryState.meta.miningContract] = { tokenIdSet: new Set(tokenIds), address: poolEntryState.meta.miningContract, poolEntry };

                for (let tokenIdx = 0; tokenIdx < tokenIds.length; tokenIdx ++) {
                    const tokenId = tokenIds[tokenIdx]
                    stakedTokenId2PoolIdx.set(tokenId, poolEntryIdx)
                    stakedTokenId2ListIdx.set(tokenId, stakedTokenIdList.length + tokenIdx)
                }                
                stakedTokenIdList.push(...tokenIds);
            }

            const secondCalling = [] as string[]
            const secondCallingAddress = [] as string[]
            for (let i = 0; i < unstakedTokenTotal; i ++) {
                secondCalling.push(liquidityManagerContract.methods.tokenOfOwnerByIndex(account, i).encodeABI())
                secondCallingAddress.push(LIQUIDITY_MANAGER_ADDRESS[chainId])
            }

            const stakedInfoSecondCallingStart = secondCalling.length

            for (const stakedTokenId of stakedTokenIdList) {
                const poolEntryIdx = stakedTokenId2PoolIdx.get(stakedTokenId) as number
                const poolEntryState = rootState.farmFixRangeiZi.poolEntryList[poolEntryIdx]
                const miningContract = allMiningContracts[poolEntryIdx]
                secondCalling.push(miningContract.methods.tokenStatus(stakedTokenId).encodeABI())
                secondCallingAddress.push(poolEntryState.meta.miningContract)
                secondCalling.push(miningContract.methods.pendingReward(stakedTokenId).encodeABI())
                secondCallingAddress.push(poolEntryState.meta.miningContract)
            }

            const secondCallingResult = await multicallContract.methods.multicall(secondCallingAddress, secondCalling).call()
            const tokenIdList: string[] = secondCallingResult.results.slice(0, unstakedTokenTotal).map((e: string)=>new BigNumber(e).toFixed(0))

            // get all positions data by tokenId list
            const allTokenId = [...tokenIdList, ...stakedTokenIdList]
            const liquidityMulticallData = allTokenId.map(tokId => liquidityManagerContract.methods.liquidities(tokId).encodeABI())
            const liquidityResult: string[] = await liquidityManagerContract.methods.multicall(liquidityMulticallData).call().then((ret: string[]) => ret)
            const liquidityiZi: LiquiditiesResponse[] = liquidityResult.map((p, i) => {
                //position: positionResponse
                const res = decodeMethodResult(liquidityManagerContract, 'liquidities', p)
                return res
            })
            const poolMetaMulticallData = liquidityiZi.map(l=>liquidityManagerContract.methods.poolMetas(l.poolId).encodeABI())
            const poolMetaResult: string[] = await liquidityManagerContract.methods.multicall(poolMetaMulticallData).call().then((ret: string[]) => ret)
            const poolMeta: PoolMetasResponse[] = poolMetaResult.map((p, i)=> {
                return decodeMethodResult(liquidityManagerContract, 'poolMetas', p)
            })
            const liquidities: LiquidityEntry[] = liquidityiZi.map((p, i)=>{
                const liquidity = {
                    nftId: allTokenId[i],
                    liquidity: liquidityiZi[i].liquidity,
                    leftPoint: Number(liquidityiZi[i].leftPt),
                    rightPoint: Number(liquidityiZi[i].rightPt),
                } as LiquidityEntry
                return liquidity
            })

            // split positions into staked and unstaked
            const stakedTokenIdSet = new Set(stakedTokenIdList);
            const stakedLiquidityList = liquidities.filter((l)=>stakedTokenIdSet.has(l.nftId))

            // get pendingReward for each stakedPosition
            for (const stakedLiquidity of stakedLiquidityList) {
                const stakedTokenId = stakedLiquidity.nftId
                const miningPool = Object.values(miningPoolData).filter(d => d.tokenIdSet.has(stakedTokenId))[0]
                if (miningPool.poolEntry.meta.contractVersion === FarmFixRangeiZiContractVersion.V1) {
                    const poolIdx = stakedTokenId2PoolIdx.get(stakedTokenId) as number
                    const listIdx = stakedTokenId2ListIdx.get(stakedTokenId) as number
                    const miningContract = allMiningContracts[poolIdx]
                    // const miningContract = getMiningFixRangeiZiV1Contract(miningPool.poolEntry.meta.miningContract, web3);
                    stakedLiquidity.earned = decodeMethodResult(miningContract, 'pendingReward', secondCallingResult.results[stakedInfoSecondCallingStart + listIdx * 2 + 1])
                }
            }

            // pure function calculate data
            for (const liquidityIdx in liquidities) {
                const liquidity = liquidities[liquidityIdx]
                const isStaked = stakedTokenIdSet.has(liquidity.nftId);
                const miningPools: miningPoolValueType[] = [];

                if (isStaked) {
                    const miningPool = Object.values(miningPoolData).find(d => d.tokenIdSet.has(liquidity.nftId)) as miningPoolValueType;
                    if (miningPool) {
                        miningPools.push(miningPool);
                    }
                } else {
                    const feeTier = toFeeNumber(Number(poolMeta[liquidityIdx].fee))
                    const positionPoolKeyV1 = getPositionPoolKey(poolMeta[liquidityIdx].tokenX, poolMeta[liquidityIdx].tokenY, feeTier, FarmFixRangeiZiContractVersion.V1);
                    const miningPoolV1 = Object.values(miningPoolData).find(d => d.poolEntry.meta.liquidityPoolKey === positionPoolKeyV1) as miningPoolValueType;
                    if (miningPoolV1) {
                        miningPools.push(miningPoolV1);
                    }

                    const positionPoolKeyVeiZi = getPositionPoolKey(poolMeta[liquidityIdx].tokenX, poolMeta[liquidityIdx].tokenY, feeTier, FarmFixRangeiZiContractVersion.VEIZI);
                    const miningPoolVeiZi = Object.values(miningPoolData).find(d => d.poolEntry.meta.liquidityPoolKey === positionPoolKeyVeiZi) as miningPoolValueType;
                    if (miningPoolVeiZi) {
                        miningPools.push(miningPoolVeiZi);
                    }
                }

                for (const miningPool of miningPools) {

                    const { meta, data } = rootState.farmFixRangeiZi.poolEntryList.find(p => p.meta.miningContract === miningPool.address) as PoolEntryState;
                    const poolEntry = miningPool.poolEntry;
                    const [tokenPriceA, tokenPriceB] = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB)
                    );
    
                    const liquidityEntry = { nftId: liquidity.nftId.toString(), isStaked } as LiquidityEntry;
    
    
                    liquidityEntry.leftPoint = parseFloat(liquidityiZi[liquidityIdx].leftPt)
                    liquidityEntry.rightPoint = parseFloat(liquidityiZi[liquidityIdx].rightPt)
                    liquidityEntry.liquidity = liquidityiZi[liquidityIdx].liquidity

                    const vlScale = meta.useOriginLiquidity ? 1 : 1e6;

                    liquidityEntry.vLiquidity = getVLiquidity(
                        data.rewardUpperTick,
                        data.rewardLowerTick,
                        liquidityEntry.rightPoint,
                        liquidityEntry.leftPoint,
                        new BigNumber(liquidityEntry.liquidity),
                        vlScale,
                    )
                    const {amountX, amountY} = getLiquidityValue(
                        liquidityEntry.liquidity,
                        liquidityEntry.leftPoint,
                        liquidityEntry.rightPoint,
                        data.currentPoint,
                        data.currentLiquidity,
                        data.currentLiquidityX
                    )
                    const tokenX = meta.tokenA.address.toLowerCase() < meta.tokenB.address.toLowerCase() ? meta.tokenA: meta.tokenB
                    const tokenY = meta.tokenA.address.toLowerCase() > meta.tokenB.address.toLowerCase() ? meta.tokenA: meta.tokenB
                    const tokenXPriceDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenX)
                    const tokenYPriceDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenY)
                    const amountXDecimal = amount2Decimal(new BigNumber(amountX), tokenX) ?? 0
                    const amountYDecimal = amount2Decimal(new BigNumber(amountY), tokenY) ?? 0

                    liquidityEntry.amountADecimal = (meta.tokenA.address === tokenX.address) ? amountXDecimal : amountYDecimal
                    liquidityEntry.amountBDecimal = (meta.tokenA.address === tokenY.address) ? amountXDecimal : amountYDecimal

                    liquidityEntry.tokenAWorth = liquidityEntry.amountADecimal * tokenPriceA
                    liquidityEntry.tokenBWorth = liquidityEntry.amountBDecimal * tokenPriceB

                    liquidityEntry.capital = amountXDecimal * tokenXPriceDecimal + amountYDecimal * tokenYPriceDecimal

                    liquidityEntry.TVL = liquidityEntry.capital

                    const {priceLowerDecimalAByB, priceUpperDecimalAByB} = getPriceRangeDecimal(meta.tokenA, meta.tokenB, liquidityEntry.leftPoint, liquidityEntry.rightPoint)
                    const {priceLowerUndecimalAByB, priceUpperUndecimalAByB} = getPriceRangeUndecimal(meta.tokenA, meta.tokenB, liquidityEntry.leftPoint, liquidityEntry.rightPoint)

                    liquidityEntry.minPriceUndecimal = priceLowerUndecimalAByB
                    liquidityEntry.maxPriceUndecimal = priceUpperUndecimalAByB
                    liquidityEntry.minPriceDecimal = priceLowerDecimalAByB
                    liquidityEntry.maxPriceDecimal = priceUpperDecimalAByB
    
                    liquidityEntry.earned = liquidity.earned;

                    console.log('earned: ', liquidityEntry.earned)
    
                    if (isStaked && meta.iZiBoost) {
                        console.log('data: ', data)
                        
                        const poolIdx = stakedTokenId2PoolIdx.get(liquidityEntry.nftId) as number
                        const listIdx = stakedTokenId2ListIdx.get(liquidityEntry.nftId) as number
                        const miningContract = allMiningContracts[poolIdx]
                        // const miningContract = getMiningFixRangeiZiV1Contract(miningPool.poolEntry.meta.miningContract, web3);
                        const tokenStatus = decodeMethodResult(miningContract, 'tokenStatus', secondCallingResult.results[stakedInfoSecondCallingStart + listIdx * 2])

                        liquidityEntry.niZi = tokenStatus.nIZI;
    
                        const vliquidityShare = new BigNumber(liquidityEntry.vLiquidity).div(data.totalVLiquidity);
                        const iZiShare = (data.totalNiZi === '0') ? new BigNumber(1) : new BigNumber(liquidityEntry.niZi).div(data.totalNiZi);

                        console.log('data apr: ', data.apr)
                      

                        liquidityEntry.currentAPR = data.apr[0] * (1 + Number(iZiShare.times(1.5).div(vliquidityShare)));
                        if (liquidityEntry.currentAPR > data.apr[1]) {
                            liquidityEntry.currentAPR = data.apr[1];
                        }
                        //validVLiquidity / (0.4 * vLiquidity) * data.apr[0];
                        liquidityEntry.niZiDecimal = amount2Decimal(new BigNumber(tokenStatus.nIZI), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
                    }
    
                    /// check if position is valid that the intersection between position and mining pool reward range is not empty.
                    if ((liquidityEntry.minPriceUndecimal <= data.rewardMaxPriceUndecimalAByB && liquidityEntry.minPriceUndecimal >= data.rewardMinPriceUndecimalAByB) ||
                        (liquidityEntry.maxPriceUndecimal <= data.rewardMaxPriceUndecimalAByB && liquidityEntry.maxPriceUndecimal >= data.rewardMinPriceUndecimalAByB) ||
                        (liquidityEntry.maxPriceUndecimal >= data.rewardMaxPriceUndecimalAByB && liquidityEntry.minPriceUndecimal <= data.rewardMinPriceUndecimalAByB)) {
    
                        if (isStaked) {
                            poolEntry.stakedLiquidityList.push(liquidityEntry);
                            // staked positionEntry will only occur once (either V2 or VEIZI)
                            poolEntry.userData.capital += liquidityEntry.capital;
                        } else {
                            poolEntry.liquidityList.push(liquidityEntry);
                        }
                    }
                }

            }
            const updatePoolListData = Object.values(miningPoolData).map(m => m.poolEntry)

            // 9. miningContract is approved
            const miningContractList = Object.keys(miningPoolData)
            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmFixRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntryState = rootState.farmFixRangeiZi.poolEntryList[poolEntryIdx]
                miningPoolData[poolEntryState.meta.miningContract].poolEntry.userData.isApprovedForAll = isApprovedResult[poolEntryIdx]
                const { meta, data } = poolEntryState
                if (meta.contractVersion === FarmFixRangeiZiContractVersion.VEIZI) {
                    // todo: fill userData of veiZi in the future
                    // const miningDynamicRangeBoostVeiZi = getMiningDynamicRangeVeiZiContract(miningContractList[i], web3);
                    // const userStatus = await miningDynamicRangeBoostVeiZi?.methods.userStatus(account).call();
                    // const userData = miningPoolData[miningContractList[i]].poolEntry.userData;
                    // userData.vLiquidity = Number(userStatus.vLiquidity);
                    // userData.validVeiZi = Number(userStatus.validVeiZi);
                    // userData.apr = veiZiBoostAPR({data} as PoolEntryState, userData.vLiquidity, userData.capital, meta.veiZiBoost? userData.veiZi : 0);
                }
            }

            // TODO save positions and positionManagerContract address
            dispatch.farmFixRangeiZi.setPositionData(updatePoolListData)
            // dispatch.farm.setUserDataLoading(false);
            console.log(`initPosition end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async refreshPoolListDataAndPosition(refreshPoolListDataAndPositionParams: RefreshPoolListDataAndPositionParams): Promise<void> {
            await dispatch.farmFixRangeiZi.initPoolListData(refreshPoolListDataAndPositionParams as InitPoolListDataParams);
            await dispatch.farmFixRangeiZi.initLiquidity(refreshPoolListDataAndPositionParams as InitLiquidityParams);
        },
        async cleanPositionIfExist(account: string | null | undefined, rootState): Promise<void> {
            if (!isAddress(account as string)) { return; }
            if (!rootState.farmFixRangeiZi.poolEntryList.find(p => p.liquidityList?.length || p.stakedLiquidityList?.length)) { return; }
            // clean user data
            dispatch.farmFixRangeiZi.setPositionData([]);
            console.info('cleanPosition end');
        },
    })
});
