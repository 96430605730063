import React from 'react';
import { Box, Flex, HStack, Image, Link, useColorMode, VStack } from '@chakra-ui/react';
import { links } from '../../../config/links';
import { getColorThemeSelector } from '../../../utils/funcs';

const MediaList: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <HStack w={{ base: '219px', sm: '184px' }} p="0" mb="17px" alignSelf="center">
            <Box flex="1">
                <Link target="_blank" href={links.telegram} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={colorTheme('/assets/icons/Telegram.svg', '/assets/icons/darkTelegram.svg')}
                        fallbackSrc={colorTheme('/assets/icons/Telegram.svg', '/assets/icons/darkTelegram.svg')}
                    ></Image>
                </Link>
            </Box>
            <Box flex="1">
                <Link target="_blank" href={links.twitter} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={colorTheme('/assets/icons/Twitter.svg', '/assets/icons/darkTwitter.svg')}
                        fallbackSrc={colorTheme('/assets/icons/Twitter.svg', '/assets/icons/darkTwitter.svg')}
                    ></Image>
                </Link>
            </Box>
            <Box flex="1">
                <Link target="_blank" href={links.medium} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={colorTheme('/assets/icons/Medium.svg', '/assets/icons/darkMedium.svg')}
                        fallbackSrc={colorTheme('/assets/icons/Medium.svg', '/assets/icons/darkMedium.svg')}
                    ></Image>
                </Link>
            </Box>
            <Box flex="1">
                <Link target="_blank" href={links.discord} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={colorTheme('/assets/icons/Discord.svg', '/assets/icons/darkDiscord.svg')}
                        fallbackSrc={colorTheme('/assets/icons/Discord.svg', '/assets/icons/darkDiscord.svg')}
                    ></Image>
                </Link>
            </Box>
            <Box flex="1">
                <Link target="_blank" href={links.reddit} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={colorTheme('/assets/icons/Reddit.svg', '/assets/icons/darkReddit.svg')}
                        fallbackSrc={colorTheme('/assets/icons/Reddit.svg', '/assets/icons/darkReddit.svg')}
                    ></Image>
                </Link>
            </Box>
        </HStack>
    );
};

export default MediaList;
