import { Box, BoxProps, HStack, useColorMode, VStack, Divider, Center, Stack, Switch, Tooltip, IconButton, Icon } from '@chakra-ui/react';

import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { defaultPrecision, getColorThemeSelector } from '../../../../utils/funcs';
import { Text } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { CustomNumberInput } from '../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { i_text_copy, i_text_copy_bold } from '../../../../style';
import { TokenIcons } from '../../components/TokenIcons';
import { FeeRate } from '../../components/FeeRate';
import { useEffect, useMemo, useState } from 'react';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { AccountTokenParams } from '../../../../state/models/account/account';
import { formatNumber } from '../../../../utils/tokenMath';
import { isNumeric } from '../../../../utils/valid';
import { useBoxContract, useLiquidityManagerContract } from '../../../../hooks/useContracts';
import CurrentPriceBlock from '../../../components/CurrentPriceBlock';
import { getFieldValidateInfo, validateMintForm } from '../../../../state/models/trade/liquidity/validationHelper';
import { Chart } from '../../components/Chart/Chart';
import useTokenEntity, { TokenEntity } from '../../../../state/models/hooks/useTokenEntity';
import TokenAmountInput from '../../components/TokenAmountInput';
import { BOX_ADDRESS, LIQUIDITY_MANAGER_ADDRESS } from '../../../../config/trade/tradeContracts';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { TapModeStatus } from '../../../../state/models/trade/tap/types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { PreQueryParams } from '../../../../state/models/trade/aggregator/model';
import { MULTICALL_ADDRESS } from '../../../../config/multicall/multicallContracts';
import { getMulticallContract } from '../../../../utils/contractHelpers';
import { TapConfirmIncModal } from './TapConfirmIncModel';
import { TAP_PROXY_ADDRESS } from '../../../../state/models/trade/tap/config';
import {
    BoxIncLiquidityFromIzumiParams,
    IncLiquidityFromIzumiParams,
    InitiZiSwapIncFormParams,
} from '../../../../state/models/trade/liquidity/models/mintModel';
import { LiquidityDetail } from '../../../../state/models/trade/liquidity/types';
import { izumiFeeToTickSpacingMapping } from '../../../../utils/tickMath';
import { GetApprovedForParams, SetNFTApprovedForParams } from '../../../../state/models/trade/liquidity/models/liquidityListModel';
import { getChain, getTxLink } from '../../../../config/chains';

type AddLiquidityModalProps = {
    entry?: LiquidityDetail;
    isOpen: boolean | any;
    onClose: any;
    handleRefreshLiquidity: () => void;
} & BoxProps;

// TODO render without list
export const AddLiquidityModal: React.FC<AddLiquidityModalProps> = (props) => {
    const { entry, isOpen, onClose, handleRefreshLiquidity, ...rest } = props;
    const { t } = useTranslation();
    const { chainId, web3, account } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { iZiSwapMintOrIncForm, iZiSwapLiquidityList } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const liquidityManagerContract = useLiquidityManagerContract();
    const [togglePrice, setTogglePrice] = useState(false);

    const boxContract = useBoxContract();

    const liquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];
    const boxAddress = BOX_ADDRESS[chainId];

    const { gasPrice } = useGasPrice();

    const nftNotNeedBox = !entry?.tokenX.wrapTokenAddress && !entry?.tokenY.wrapTokenAddress;
    const nftNeedApproveForBox = !nftNotNeedBox && !iZiSwapLiquidityList.isApprovedForBox;
    const [showTapConfirmModal, setShowTapConfirmModal] = useState(false);

    let tokenXEntity = undefined as unknown as TokenEntity;
    let tokenYEntity = undefined as unknown as TokenEntity;

    const tokenXNotWrapToken = !entry?.tokenX.wrapTokenAddress;
    const tokenYNotWrapToken = !entry?.tokenY.wrapTokenAddress;
    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId];
    const noWrapToken = tokenXNotWrapToken && tokenYNotWrapToken;
    if (noWrapToken) {
        tokenXEntity = useTokenEntity(entry?.tokenX as any, liquidityManagerAddress);
        tokenYEntity = useTokenEntity(entry?.tokenY as any, liquidityManagerAddress);
    } else {
        tokenXEntity = tokenXNotWrapToken
            ? useTokenEntity(entry?.tokenX as any, boxAddress)
            : useTokenEntity(entry?.tokenX as any, entry?.tokenX.wrapTokenAddress, boxAddress);
        tokenYEntity = tokenYNotWrapToken
            ? useTokenEntity(entry?.tokenY as any, boxAddress)
            : useTokenEntity(entry?.tokenY as any, entry?.tokenY.wrapTokenAddress, boxAddress);
    }

    const { amount0Desired, amount1Desired } = useMemo(() => {
        const amount0Desired = iZiSwapMintOrIncForm.amount0Desired?.toFixed(0) ?? '0';
        const amount1Desired = iZiSwapMintOrIncForm.amount1Desired?.toFixed(0) ?? '0';
        return {
            amount0Desired,
            amount1Desired,
        };
    }, [iZiSwapMintOrIncForm]);

    useEffect(() => {
        if (!entry || !account || !web3) {
            return;
        }
        const tokens = [entry.tokenX, entry.tokenY];
        for (const token of tokens) {
            const param = {
                token,
                account,
                web3,
                chainId,
            } as AccountTokenParams;
            dispatch.token.fetchTokenPriceIfMissing(token);
            dispatch.account.fetchTokenBalanceIfMissing(param);
        }
    }, [account, entry, chainId]);
    const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3);

    const fetchPoolInfo = (token0: TokenInfoFormatted, token1: TokenInfoFormatted, fee: FeeTier, isTapMode: boolean) => {
        if (!token0.symbol || !token1.symbol || !fee || !chainId || !entry) {
            return;
        }
        if (!isTapMode) {
            dispatch.iZiSwapMintOrIncForm.initIncForm({
                liquidity: entry,
                spacingMapping: izumiFeeToTickSpacingMapping,
                isTapMode,
            } as InitiZiSwapIncFormParams);
        } else {
            dispatch.swapPreQueryModel
                .preQuery({
                    chainId,
                    web3,
                    tokenIn: token0,
                    tokenOut: token1,
                    multicall: multicallContract,
                } as PreQueryParams)
                .then(() => {
                    dispatch.iZiSwapMintOrIncForm.initIncForm({
                        liquidity: entry,
                        spacingMapping: izumiFeeToTickSpacingMapping,
                        isTapMode,
                    } as InitiZiSwapIncFormParams);
                })
                .catch((e: any) => {
                    console.info('error:   ', e.message);
                });
        }
    };
    const toast = useCustomToast();
    if (!entry) {
        return null;
    }

    const validateResult = validateMintForm(iZiSwapMintOrIncForm, [tokenXEntity.tokenBalance(), tokenYEntity.tokenBalance()]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '338px', sm: '788px' }} h="782px" title={t('Add Liquidity')} {...rest}>
            <Divider m="0" />
            <Stack
                w="100%"
                justifyContent="space-between"
                alignItems="center"
                pr={{ base: '0px', sm: '30px' }}
                direction={{ base: 'column', sm: 'row' }}
            >
                <HStack w={{ base: 'unset', sm: '200px' }} spacing="20px" p={{ base: '30px 0px 14px 0px', sm: '30px 12px 14px 30px' }}>
                    <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={togglePrice} />
                    <FeeRate tokenA={entry.tokenX} tokenB={entry.tokenY} feeTier={entry.fee} initialToggle={togglePrice} />
                </HStack>
                <CurrentPriceBlock
                    tokenA={entry.tokenX}
                    tokenB={entry.tokenY}
                    price={entry.currentPriceDecimal}
                    handleToggle={() => {
                        setTogglePrice(!togglePrice);
                    }}
                    toggle={togglePrice}
                    mt="10px !important"
                    w="270px"
                />
            </Stack>

            <Stack
                w="100%"
                h={{ base: 'unset', sm: '430px' }}
                spacing="16px"
                mt="30px !important"
                direction={{ base: 'column', sm: 'row' }}
            >
                <VStack w={{ base: '100%', sm: '293px' }} h="100%" position="relative" mt="-10px !important">
                    <HStack mb="9px !important" p="0 !important" spacing="30px">
                        <Text variant="caption-bold" alignSelf="center">
                            {t('Volume')}
                        </Text>
                        <Card
                            w="100%"
                            variant="deep"
                            py="2px"
                            px="10px"
                            display="flex"
                            //justifyContent="space-between"
                        >
                            <HStack spacing="10px" justifyItems="center">
                                <Text variant="caption-bold" color="#938CA4">
                                    {t('Tap')}
                                </Text>

                                <HStack>
                                    <Switch
                                        disabled={!tapProxyAddress}
                                        isChecked={iZiSwapMintOrIncForm.isTapMode ? true : false}
                                        onChange={(e) => {
                                            if (
                                                !iZiSwapMintOrIncForm.token0.wrapTokenAddress &&
                                                !iZiSwapMintOrIncForm.token1.wrapTokenAddress
                                            ) {
                                                fetchPoolInfo(
                                                    iZiSwapMintOrIncForm.token0,
                                                    iZiSwapMintOrIncForm.token1,
                                                    iZiSwapMintOrIncForm.fee,
                                                    e.target.checked
                                                );
                                            }
                                        }}
                                        size="md"
                                    />
                                    <Tooltip
                                        label={
                                            'If switch on, some tokens A may swap into token B first and then add as a Liquidity together.'
                                        }
                                    >
                                        <IconButton
                                            size="sm"
                                            aria-label=""
                                            variant="ghost"
                                            isRound={true}
                                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                        />
                                    </Tooltip>
                                </HStack>
                            </HStack>
                        </Card>
                    </HStack>

                    <TokenAmountInput
                        h="182px"
                        token={entry.tokenX}
                        balance={tokenXEntity.tokenBalance()}
                        handleSetValue={(value: any) => {
                            isNumeric(value) &&
                                dispatch.iZiSwapMintOrIncForm.setMintFormAmountDesired({
                                    isDesired0: true,
                                    desiredAmountDecimal: value,
                                    currentPoint: Number(entry.currentPt),
                                });
                        }}
                        price={tokenXEntity.tokenPrice()}
                        errorInfo={getFieldValidateInfo(validateResult, 'amount0Desired')}
                        inputValue={
                            iZiSwapMintOrIncForm.amount0DecimalDesired && Number(iZiSwapMintOrIncForm.amount0DecimalDesired).toFixed(4)
                        }
                    >
                        <Text variant="caption" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.300')}>
                            {t('Current Liquidity')} :
                        </Text>

                        <Text variant="caption" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.300')}>
                            {formatNumber(entry.tokenXLiquidityDecimal, 2, 2, true)}
                        </Text>
                    </TokenAmountInput>
                    <TokenAmountInput
                        h="182px"
                        token={entry.tokenY}
                        balance={tokenYEntity.tokenBalance()}
                        handleSetValue={(value: any) => {
                            isNumeric(value) &&
                                dispatch.iZiSwapMintOrIncForm.setMintFormAmountDesired({
                                    isDesired0: false,
                                    desiredAmountDecimal: value,
                                    currentPoint: Number(entry.currentPt),
                                });
                        }}
                        price={tokenYEntity.tokenPrice()}
                        errorInfo={getFieldValidateInfo(validateResult, 'amount1Desired')}
                        inputValue={
                            iZiSwapMintOrIncForm.amount1DecimalDesired && Number(iZiSwapMintOrIncForm.amount1DecimalDesired).toFixed(4)
                        }
                    >
                        <Text variant="caption" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.300')}>
                            {t('Current Liquidity')} :
                        </Text>

                        <Text variant="caption" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.300')}>
                            {formatNumber(entry.tokenYLiquidityDecimal, 2, 2, true)}
                        </Text>
                    </TokenAmountInput>

                    <Box
                        position="absolute"
                        borderRadius="50%"
                        border={colorTheme('6px solid #ebebf7', 'undefined')}
                        w="44px"
                        h="44px"
                        background={colorTheme('#FFFFFF', 'tertiary.400')}
                        top="224px"
                        left="50%"
                        transform="translateX(-50%) translateY(-50%)"
                        backgroundImage={process.env.PUBLIC_URL + '/assets/swap/transicon.svg'}
                        backgroundSize="18px 24px"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center"
                    />
                </VStack>

                <VStack w={{ base: '100%', sm: '394px' }} h="100%">
                    <Text variant="caption-bold" alignSelf="center" mb="20px !important">
                        {t('Price Range')}
                    </Text>

                    <Card h="372px" w="100%">
                        <VStack w="100%" h="256px" bg={colorTheme('undefined', '#222525')} alignItems="center" pt="14px">
                            <HStack alignSelf="center">
                                <Text variant="caption" className={i_text_copy_bold} color={colorTheme('tertiary.400', 'tertiary.300')}>
                                    {t('Current Price')}:
                                </Text>
                                <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.50')} className={i_text_copy}>
                                    1 {togglePrice ? entry.tokenY.symbol : entry.tokenX.symbol} =
                                    {formatNumber(togglePrice ? 1 / entry.currentPriceDecimal : entry.currentPriceDecimal, 2, 2, true)}
                                    {togglePrice ? entry.tokenX.symbol : entry.tokenY.symbol}
                                </Text>
                            </HStack>
                            <Chart
                                dim={{
                                    width: 345,
                                    height: 220,
                                }}
                                zoom={0}
                                currentPrice={togglePrice ? 1 / entry.currentPriceDecimal : entry.currentPriceDecimal}
                                minPrice={togglePrice ? 1 / entry.maxPriceDecimal : entry.minPriceDecimal}
                                maxPrice={togglePrice ? 1 / entry.minPriceDecimal : entry.maxPriceDecimal}
                            />
                        </VStack>

                        <VStack pt="10px">
                            <HStack w="80%" justifyContent="space-between">
                                <VStack w="149px">
                                    <Text variant="caption" className={i_text_copy_bold} color={colorTheme('tertiary.400', 'tertiary.300')}>
                                        {t('Min Price')} :
                                    </Text>
                                    <Card variant="deep">
                                        <CustomNumberInput
                                            disabled={true}
                                            inputValue={
                                                togglePrice
                                                    ? formatNumber(1 / entry.maxPriceDecimal, 2, 2, true)
                                                    : formatNumber(entry.minPriceDecimal, 2, 2, true)
                                            }
                                            errorInfo=""
                                        />
                                    </Card>
                                </VStack>
                                <VStack w="149px">
                                    <Text variant="caption" className={i_text_copy_bold} color={colorTheme('tertiary.400', 'tertiary.300')}>
                                        {t('Max Price')} :
                                    </Text>
                                    <Card variant="deep">
                                        <CustomNumberInput
                                            disabled={true}
                                            inputValue={
                                                togglePrice
                                                    ? formatNumber(1 / entry.minPriceDecimal, 2, 2, true)
                                                    : formatNumber(entry.maxPriceDecimal, 2, 2, true)
                                            }
                                            errorInfo=""
                                        />
                                    </Card>
                                </VStack>
                            </HStack>
                        </VStack>
                    </Card>
                </VStack>
            </Stack>
            {iZiSwapMintOrIncForm.isTapMode && (
                <Center w="100%">
                    <CustomButton
                        disabled={
                            validateResult !== undefined ||
                            !(iZiSwapMintOrIncForm.amount0DecimalDesired > 0 || iZiSwapMintOrIncForm.amount1DecimalDesired > 0)
                        }
                        mt="24px !important"
                        variant="purple"
                        text={t('Tap And Add')}
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            setShowTapConfirmModal(true);
                        }}
                    />
                </Center>
            )}
            <Center w="100%">
                <CustomButton
                    hidden={
                        amount0Desired === '0' ||
                        tokenXEntity.isApproved(amount0Desired) ||
                        !account ||
                        !entry?.tokenX.symbol ||
                        iZiSwapMintOrIncForm.isTapMode
                    }
                    mt="24px !important"
                    variant="purple"
                    w={{ base: '100%', sm: '469px' }}
                    h="62px"
                    fontSize="14px"
                    text={t('Approve') + ' ' + entry?.tokenX.symbol}
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        tokenXEntity
                            .handleApprove()
                            .on('transactionHash', (hash: string) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(hash, chain);
                                }
                                toast('info', 'Ongoing', undefined, toastLink);
                            })
                            .then((e: any) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                }
                                toast('success', 'Approve successfully', undefined, toastLink);
                                tokenXEntity.handleApproveSuccess();
                            })
                            .catch((e: any) => {
                                console.info('error   :', e.message);
                            });
                    }}
                />
            </Center>
            {!tokenXNotWrapToken && (
                <Center w="100%">
                    <CustomButton
                        hidden={
                            amount0Desired === '0' ||
                            tokenXEntity.isDepositApproved() ||
                            !account ||
                            !entry?.tokenX.symbol ||
                            iZiSwapMintOrIncForm.isTapMode
                        }
                        mt="24px !important"
                        variant="purple"
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        text={t('Approve Deposit') + ' ' + entry?.tokenX.symbol}
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then((e: any) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    tokenXEntity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error:  ', e.message);
                                });
                        }}
                    />
                </Center>
            )}
            <Center w="100%">
                <CustomButton
                    hidden={
                        amount1Desired === '0' ||
                        tokenYEntity.isApproved(amount1Desired) ||
                        !account ||
                        !entry?.tokenY.symbol ||
                        iZiSwapMintOrIncForm.isTapMode
                    }
                    mt="24px !important"
                    variant="purple"
                    w={{ base: '100%', sm: '469px' }}
                    h="62px"
                    fontSize="14px"
                    text={t('Approve') + ' ' + entry?.tokenY.symbol}
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        tokenYEntity
                            .handleApprove()
                            .on('transactionHash', (hash: string) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(hash, chain);
                                }
                                toast('info', 'Ongoing', undefined, toastLink);
                            })
                            .then((e: any) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                }
                                toast('success', 'Approve successfully', undefined, toastLink);
                                tokenYEntity.handleApproveSuccess();
                            })
                            .catch((e: any) => {
                                console.info('error   :', e.message);
                            });
                    }}
                />
            </Center>
            {!tokenYNotWrapToken && (
                <Center w="100%">
                    <CustomButton
                        hidden={
                            amount1Desired === '0' ||
                            tokenYEntity.isDepositApproved() ||
                            !account ||
                            !entry?.tokenY.symbol ||
                            iZiSwapMintOrIncForm.isTapMode
                        }
                        mt="24px !important"
                        variant="purple"
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        text={t('Approve Deposit') + ' ' + entry?.tokenY.symbol}
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenYEntity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then((e: any) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'Approve deposit successfully', undefined, toastLink);
                                    tokenYEntity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error:  ', e.message);
                                });
                        }}
                    />
                </Center>
            )}
            {!nftNeedApproveForBox && !iZiSwapMintOrIncForm.isTapMode && (
                <Center w="100%">
                    <CustomButton
                        disabled={validateResult !== undefined}
                        mt="24px !important"
                        variant="purple"
                        text={t('Add')}
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            if (noWrapToken) {
                                dispatch.iZiSwapMintOrIncForm
                                    .incLiquidity({
                                        account,
                                        chainId,
                                        liquidityManagerContract: liquidityManagerContract,
                                        gasPrice,
                                        onGoingCallback(toastLink: any) {
                                            toast('info', 'Ongoing', undefined, toastLink);
                                        },
                                    } as IncLiquidityFromIzumiParams)

                                    .then((e) => {
                                        if (amount0Desired !== '0') {
                                            tokenXEntity.handleApproveSuccess().catch((e) => {
                                                console.log('refresh approve error: ', e);
                                            });
                                        }
                                        if (amount1Desired !== '0') {
                                            tokenYEntity.handleApproveSuccess().catch((e) => {
                                                console.log('refresh approve error: ', e);
                                            });
                                        }
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Add successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    });
                            } else {
                                dispatch.iZiSwapMintOrIncForm
                                    .incBoxLiquidity({
                                        account,
                                        chainId,
                                        boxContract,
                                        gasPrice,
                                        onGoingCallback(toastLink: any) {
                                            toast('info', 'Ongoing', undefined, toastLink);
                                        },
                                    } as BoxIncLiquidityFromIzumiParams)
                                    .then((e) => {
                                        if (amount0Desired !== '0') {
                                            tokenXEntity.handleApproveSuccess().catch((e) => {
                                                console.log('refresh approve error: ', e);
                                            });
                                        }
                                        if (amount1Desired !== '0') {
                                            tokenYEntity.handleApproveSuccess().catch((e) => {
                                                console.log('refresh approve error: ', e);
                                            });
                                        }
                                        if (chain) {
                                            toastLink.title = 'View on ' + chain.name;
                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                        }
                                        toast('success', 'Add successfully', undefined, toastLink);
                                        handleRefreshLiquidity();
                                    });
                            }
                        }}
                    />
                </Center>
            )}
            {nftNeedApproveForBox && (
                <Center w="100%">
                    <CustomButton
                        mt="24px !important"
                        variant="purple"
                        text="Approve Box"
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapLiquidityList
                                .setApprovedFor({
                                    chainId,
                                    account,
                                    liquidityManagerContract,
                                    gasPrice,
                                    operatorAddress: BOX_ADDRESS[chainId],
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                } as SetNFTApprovedForParams)
                                .then((e: any) => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'Approve box successfully', undefined, toastLink);

                                    dispatch.iZiSwapLiquidityList.getApprovedForBox({
                                        chainId,
                                        account,
                                        liquidityManagerContract,
                                    } as GetApprovedForParams);
                                });
                        }}
                    />
                </Center>
            )}

            {showTapConfirmModal && (
                <TapConfirmIncModal
                    isOpen={showTapConfirmModal}
                    onClose={() => {
                        dispatch.iZiSwapMintOrIncForm.setTapModeStatus(TapModeStatus.Modified);
                        setShowTapConfirmModal(false);
                    }}
                    currentPoint={entry.currentPt}
                    onSuccess={() => {}}
                />
            )}
        </Modal>
    );
};
