import { HStack, Image, Popover, PopoverContent, PopoverTrigger, Stack, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import tokens, { tokenSymbol2token } from '../../../../config/tokens';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { i_text_copy_bold } from '../../../../iZUMi-UI-toolkit/src/style';
import {
    getIziSwapKlineInfoMeta,
    ResponseKlinePoolMeta,
    ResponseKlineTokenMeta,
} from '../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { getBaseQuoteOrder } from '../../../../state/models/pro/proOrderFormState/funcs';
import { noto_t3 } from '../../../../style';
import { TokenSymbol } from '../../../../types/mod';
import { getColorThemeSelector } from '../../../../utils/funcs';
import Search from './components/Search/Search';
import TokenChange from './components/TokenChange';
import TokenInfo from './components/TokenInfo';

type KlineInfoProps = {
    themeColor: any;
};
export type ResponseKlineInfoMetaWithReverse = {
    tokenX: ResponseKlineTokenMeta;
    tokenY: ResponseKlineTokenMeta;
    pool: ResponseKlinePoolMeta;
    isReverse: boolean;
};
const KlineInfo: React.FC<KlineInfoProps> = (props) => {
    const { themeColor } = props;
    const history = useHistory();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { chainId } = useWeb3WithDefault();
    const [isOpen, setIsOpen] = useState(false);
    const close = () => setIsOpen(false);
    const [pools, setPools] = useState([] as ResponseKlineInfoMetaWithReverse[]);

    const { loading, tokenList } = useTokenListFormatted();
    const getToken = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        if (tokens[symbol as TokenSymbol]) {
            return tokenSymbol2token(symbol, chainId);
        }
        return tempToken;
    };

    useEffect(() => {
        if (loading || tokenList.length === 0) {
            return;
        }
        getIziSwapKlineInfoMeta(chainId).then((r) => {
            const data = r.data.data;
            if (!r.data.is_success) {
                return;
            }
            const quoteData = data.map((item) => {
                const tokenA = getToken(item.tokenX.symbol) as TokenInfoFormatted;
                const tokenB = getToken(item.tokenY.symbol) as TokenInfoFormatted;
                const { quoteToken, baseToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                const isReverse = tokenA.symbol !== baseToken.symbol && tokenB.symbol !== quoteToken.symbol;
                const result = {
                    tokenX: { address: baseToken.address, symbol: baseToken.symbol, decimal: baseToken.decimal },
                    tokenY: { address: quoteToken.address, symbol: quoteToken.symbol, decimal: quoteToken.decimal },
                    pool: isReverse
                        ? {
                              ...item.pool,
                              initialPrice: item.pool.initialPrice !== 0 ? 1 / item.pool.initialPrice : 0,
                              latestDealPrice: item.pool.latestDealPrice !== 0 ? 1 / item.pool.latestDealPrice : 0,
                              dayPriceChange: item.pool.dayPriceChange !== 0 ? 1 / (item.pool.dayPriceChange + 1) - 1 : 0,
                          }
                        : { ...item.pool },
                    isReverse: isReverse,
                };
                return result;
            });
            setPools(quoteData);
        });
    }, [chainId, tokenList]);

    return isMobile ? (
        <Card w="100%" bg={themeColor} mt="8px" py="13px">
            <Stack h="100%">
                <HStack px="20px">
                    <Popover trigger="hover" isOpen={isOpen} onClose={close}>
                        <PopoverTrigger>
                            <Image
                                w="28px"
                                h="38px"
                                pr="10px"
                                py="10px"
                                src="/assets/pro/exchange.svg"
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}
                            ></Image>
                        </PopoverTrigger>
                        <PopoverContent
                            w="366px"
                            h="404px"
                            border="none"
                            borderRadius="6px"
                            _focus={{ outline: '2px solid transparent', outlineOffset: '2px' }}
                            bg={colorTheme('#FFFFFF', '#010303')}
                            transform={'translate(12px, 0px) !important'}
                            boxShadow={colorTheme('0px 0px 27px 5px rgba(201, 201, 201, 0.25)', '0px 0px 27px 5px rgba(14, 7, 21, 0.58)')}
                        >
                            <Search pools={pools}></Search>
                        </PopoverContent>
                    </Popover>
                    <TokenInfo></TokenInfo>
                    <CustomButton
                        w="90px"
                        h="26px"
                        className={noto_t3}
                        bg="#21B0A6"
                        borderRadius="3px"
                        color="#FFFFFF"
                        text={t('Back to Lite')}
                        ml="auto !important"
                        _hover={{ bg: '#47C87A' }}
                        onClick={() => {
                            history.push('/swap');
                        }}
                    ></CustomButton>
                </HStack>
                <TokenChange pools={pools}></TokenChange>
            </Stack>
        </Card>
    ) : (
        <Card w="100%" h="64px" bg={themeColor} mt="8px" px="22px" py="13px">
            <HStack h="100%">
                <Popover trigger="hover" isLazy={true}>
                    <PopoverTrigger>
                        <Image w="28px" h="38px" pr="10px" py="10px" src="/assets/pro/exchange.svg"></Image>
                    </PopoverTrigger>
                    <PopoverContent
                        w="427px"
                        h="404px"
                        border="none"
                        borderRadius="6px"
                        _focus={{ outline: '2px solid transparent', outlineOffset: '2px' }}
                        bg={colorTheme('#FFFFFF', '#010303')}
                        transform={'translate(12px, 0px) !important'}
                        boxShadow={colorTheme('0px 0px 27px 5px rgba(201, 201, 201, 0.25)', '0px 0px 27px 5px rgba(14, 7, 21, 0.58)')}
                    >
                        <Search pools={pools}></Search>
                    </PopoverContent>
                </Popover>
                <TokenInfo></TokenInfo>
                <TokenChange pools={pools} ml="36px !important"></TokenChange>
                <CustomButton
                    w="118px"
                    h="36px"
                    className={i_text_copy_bold}
                    bg="#21B0A6"
                    borderRadius="3px"
                    color="#FFFFFF"
                    text={t('Back to Lite')}
                    ml="auto !important"
                    _hover={{ bg: '#47C87A' }}
                    onClick={() => {
                        history.push('/swap');
                    }}
                ></CustomButton>
            </HStack>
        </Card>
    );
};

export default KlineInfo;
